export const initialNotes = `
Pause:

Infos zu den Betreuungskräften:

Sonderabsprachen:

Zum Preis:

Zur Betreuung:

Zur Wechselplanung:


Übernachtung von zwei Betreuungskräften möglich:

Sonstiges:



Preiserhöhungen:

`;

export const initialContractNotes = `
Startdatum:
Budget:
Provi:

Netzwerk/Pflegedienst:

Führerschein:

Rauchen:

Freitext:

`;
