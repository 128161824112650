import React from 'react';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { Divider } from '@material-ui/core';
import { addDays, addMonths, parseISO } from 'date-fns';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import DateSwitchSelect from '../../form/components/specialized/DateSwitchSelect';
import DateInput from '../../form/components/DateInput';
import TextInput from '../../form/components/TextInput';
import ToggleButtons from '../../form/components/ToggleButtons';
import Select from '../../form/components/Select';
import TitleBar from '../../layout/components/TitleBar';
import ConditionalField from '../../form/components/specialized/ConditionalField';
import { AGENCY_CONTRACT, PZH_CONTRACT } from '../contractTypes';
import { contractSchemaAgency } from '../schema';
import AgencyEmployeeSelect from './AgencyEmployeeSelect';
import { useContract, updateContract } from '../contractsSlice';
import { CONTRACT_RESOURCE } from '../../api/resources';
import FormPrefiller from '../../form/components/FormPrefiller';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import TerminationReasonSelect from '../../form/components/specialized/TerminationReasonSelect';
import  ContractTypeSelect from '../../form/components/specialized/ContractTypeSelect';
import { PZH_CONTRACT_CANCELLATION_PERIOD, PZH_CONTRACT_FEE_TYPE_FULL } from '../config';
import RejectTill from '../../form/components/specialized/RejectTill';
import ContractEndAtDateInput from './ContractEndAtDateInput';
import { useCustomerReminderDate } from '../../interestedCustomer/hooks';
import { useCustomer, updateCustomer } from '../../customers/customersSlice';
import { storeCareNeed } from '../../careNeeds/careNeedsSlice';
import { useContractsWithTypeByCustomerId } from '../../contracts/hooks';
import * as Yup from 'yup';
import { isDate } from 'date-fns';
import { YupId } from '../../form/schema';

const prepare = (values) => ({
    ...values,
    rejectTill: addDays(parseISO(values.startAt), PZH_CONTRACT_CANCELLATION_PERIOD),
});



const ContractForm = ({ customerId, contractId, parentContractId, type, onDone, submitRef }) => {
    const [contract] = useContract(contractId);
    const [parentContract] = useContract(parentContractId);
    const [customer] = useCustomer(contract && contract.customerId);

    const initialValues = useInitialValues(contract, {
        title: '',
        agencyId: parentContract ? parentContract.agencyId : '',
        agencyEmployeeId: parentContract ? parentContract.agencyEmployeeId : '',
        startAt: null,
        endAt: null,
        notes: '',
        sentVia: [],
        sentAt: null,
        receivedAs: '',
        receivedAt: null,
        sentToAgencyAs: '',
        sentToAgencyAt: null,
        receivedFromAgencyAs: '',
        receivedFromAgencyAt: null,
        completeness: '',
        sentBackToCustomerAt: null,
        terminatedAt: null,
        terminatedBy: '',
        terminationReason: '',
        terminationPeriodCharged: '',
        terminationSentAgencyAt: null,
        terminationConfirmationReceivedAt: null,
        terminationConfirmationSentCustomerAt: null,
        rejectedAt: null,
        contractType: contract?.contractType ? contract.contractType : PZH_CONTRACT_FEE_TYPE_FULL,
    });

    const [contracts] = useContractsWithTypeByCustomerId(customerId, PZH_CONTRACT);

    function isStartDateInUse(new_start_date, contracts) {
        const other_contracts = contracts.filter(c=>c.id !== contractId && !c.endAt);
        //console.log("oc=>",other_contracts);
        if (other_contracts == null) {
            return false;
        }
        return other_contracts.some(c => new Date(new_start_date) >= new Date(c.startAt)  && new Date(new_start_date) <= new Date(c.endAt) );
    }

    function isEndDateInUse(new_end_date, contracts) {
        const other_contracts = contracts.filter(c=>c.id !== contractId && !c.endAt);
        //console.log("oc=>",other_contracts);
        if (other_contracts == null) {
            return false;
        }
        return other_contracts.some(c => new Date(new_end_date) >= new Date(c.startAt)  && new Date(new_end_date) <= new Date(c.endAt) );
    }

    /**
     * get the latest contract based on the startAt data
     * @param {*} contracts
     * @returns
     */
    const getLatestContract = (contracts) => {
        if (contracts.length === 0) return null;

        const latest = contracts.reduce((latest, contract) => {
            return new Date(contract.startAt) > new Date(latest.startAt) ? contract : latest;
        });

        return latest;
    };

    function isLatestContract(contracts, type) {
        const latestContract = getLatestContract(contracts);
        if (type !== PZH_CONTRACT) return true;
        if (latestContract === null) return false;
        if (latestContract.id === contractId) return true;
        return false;
    }

    const contractSchemaPZH = Yup.object().shape({
        title: Yup.string(),
        agency: Yup.string(),
        agencyEmployeeId: YupId(),
        startAt: Yup.date()
            .nullable()
            .max(new Date(), 'Darf nicht in der Zukunft liegen')
            .test(
                'is-date-in-use',
                'Vertrags-Start Datum bereits in Verwendung bei einem anderen Vertrag',
                function (value) {
                    //console.log(value);
                    return !isStartDateInUse(value, contracts);
                }
            )
            .required('Pflichtfeld'),
        endAt: Yup.date()
            .nullable()
            .min(Yup.ref('startAt'), 'Darf nicht vor Beginn liegen')
            .test(
                'is-date-in-use',
                'Vertrags-Ende Datum bereits in Verwendung bei einem anderen Vertrag!',
                function (value) {
                    //console.log(value);
                    return !isEndDateInUse(value, contracts);
                }
            ),
        notes: Yup.string(),
        sentAt: Yup.date().nullable(),
        sentVia: Yup.array()
            .of(Yup.string())
            .when('sentAt', {
                is: (sentAt) => isDate(sentAt),
                then: Yup.array().of(Yup.string()).min(1).required('Pflichtfeld'),
            }),
        receivedAs: Yup.string().nullable(),
        receivedAt: Yup.date()
            .nullable()
            .min(Yup.ref('sentAt'), 'Darf nicht vor An Kunde gesendet liegen'),
        sentToAgencyAs: Yup.string(),
        sentToAgencyAt: Yup.date()
            .nullable()
            .min(Yup.ref('receivedAt'), 'Darf nicht vor Erhalten vom Kunden liegen'),
        receivedFromAgencyAs: Yup.string(),
        receivedFromAgencyAt: Yup.date()
            .nullable()
            .min(Yup.ref('sentToAgencyAt'), 'Darf nicht vor Versand an Agentur liegen'),
        completeness: Yup.string(),
        sentBackToCustomerAt: Yup.date()
            .nullable()
            .min(Yup.ref('receivedFromAgencyAt'), 'Darf nicht vor Erhalten von Agentur liegen'),
        terminatedAt: Yup.date().nullable().min(Yup.ref('startAt'), 'Darf nicht vor Beginn liegen'),
        terminatedBy: Yup.string()
            .nullable()
            .when('terminatedAt', {
                is: (terminatedAt) => isDate(terminatedAt),
                then: Yup.string()
                    .matches(/customer|pzh/, 'Entweder Kunde oder PZH')
                    .required('Pflichtfeld'),
            }),
        terminationReason: Yup.string().when('terminatedAt', {
            is: (terminatedAt) => isDate(terminatedAt),
            then: Yup.string().required('Pflichtfeld'),
        }),
        rejectTill: Yup.date().nullable(),
        rejectedAt: Yup.date().nullable().min(Yup.ref('startAt'), 'Darf nicht vor Beginn liegen'),
        contractType: Yup.string(Yup.ref('contractType')).required('Pflichtfeld'),
    });

    const { addDaysToReminder, updateCustomerReminder } = useCustomerReminderDate(customerId);
    const dispatch = useDispatch();
    const handleSubmit = useResourceSubmit(
        contractId,
        CONTRACT_RESOURCE,
        !contractId ? { customerId, type, parentContractId } : null,
        {
            onDone: (response) => {
                if (response.data.type === PZH_CONTRACT) {
                    if (
                        contract.sentAt === null &&
                        response.data.sentAt !== null &&
                        response.data.sentVia?.length > 0
                    ) {
                        const date = addDaysToReminder(
                            response.data.sentVia.includes('email') ? 1 : 2,
                            parseISO(response.data.sentAt)
                        );
                        updateCustomerReminder(date);
                    }

                    if (
                        contract.completeness !== 'complete' &&
                        response.data.completeness === 'complete'
                    ) {
                        // Automatisation: when contract complete, customer will be a customer and a BK-Bedarf is generated
                        // to get an action in "Einsatz" Dashboard
                        const today = new Date();
                        const tomorrow = new Date(today);
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        dispatch(
                            storeCareNeed({
                                careFrom: customer.careStartAt ?? tomorrow,
                                customerId: customer.id,
                            })
                        );
                        dispatch(
                            updateCustomer({
                                currentType: {
                                    name: 'contract',
                                    details: customer.currentType.details,
                                },
                                id: customer.id,
                            })
                        );
                    }

                    if (contract.terminatedAt === null && response.data.terminatedAt !== null) {
                        dispatch(
                            updateContract({ id: response.data.id, noAutoInvoiceGeneration: 1 })
                        );
                    }
                }

                return onDone ? onDone(response) : Promise.resolve();
            },
            prepare,
        }
    );

    const hasParent = (contract && contract.parentContractId) || parentContractId;
    const isAgency = (contract && contract.type === AGENCY_CONTRACT) || type === AGENCY_CONTRACT;
    const contractSchema = isAgency ? contractSchemaAgency : contractSchemaPZH;

    const islatest = isLatestContract(contracts, type);
    const disabled = !islatest;
    //console.log("contractId=>",contractId," islatest=>",islatest);
    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={contractSchema}
            subject={contract || CONTRACT_RESOURCE}
        >
            <FormPrefiller
                values={{
                    title: 'title',
                    startAt: new Date(),
                    endAt: addMonths(new Date(), 9),
                    notes: 'Notes',
                }}
            />
            <Grid container spacing={2} alignItems="flex-start">
                {hasParent && (
                    <Grid item xs={6}>
                        <TextInput name="title" label="Titel Addendum" fullWidth />
                    </Grid>
                )}
                {isAgency && (
                    <Grid item xs={12}>
                        <AgencyEmployeeSelect
                            name="agencyEmployeeId"
                            agencyFieldName="agencyId"
                            label="Ansprechpartner"
                            agencyLabel="Agentur"
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                )}
                <Grid item xs={3}>
                    <DateInput
                        name="startAt"
                        label={hasParent ? 'Gültig ab' : 'Vertragsbeginn'}
                        maxDate={!isAgency ? new Date() : null}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    {!hasParent && (
                        <ContractEndAtDateInput
                            name="endAt"
                            label="Vertragsende"
                            startAtField="startAt"
                            agencyField="agencyId"
                            fullWidth
                            disabled={disabled}
                        />
                    )}
                </Grid>
                {!isAgency && (
                    <>
                        <Grid item xs={3}>
                            <RejectTill
                                name="rejectTill"
                                startAtField="startAt"
                                label="Widerruf möglich bis"
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateInput
                                name="rejectedAt"
                                label="Widerruf am"
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <TitleBar>Anlegen</TitleBar>
                </Grid>
                <Grid item xs={3}>
                    <DateSwitchSelect
                        label="An Kunde gesendet"
                        nameOption="sentVia"
                        nameDate="sentAt"
                        options={[
                            { value: 'post', label: 'Post' },
                            { value: 'email', label: 'E-Mail' },
                            { value: 'docuSign', label: 'DocuSign' },
                        ]}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DateSwitchSelect
                        nameOption="receivedAs"
                        nameDate="receivedAt"
                        label="Erhalten vom Kunden"
                        options={[
                            { value: 'original', label: 'Original' },
                            { value: 'copy', label: 'Kopie' },
                            { value: 'docuSign', label: 'DocuSign' },
                        ]}
                        exclusive
                        disabled={disabled}
                    />
                </Grid>
                {isAgency && (
                    <>
                        <Grid item xs={3}>
                            <DateSwitchSelect
                                nameOption="sentToAgencyAs"
                                nameDate="sentToAgencyAt"
                                label="An Agentur gesendet"
                                options={[
                                    { value: 'original', label: 'Original' },
                                    { value: 'copy', label: 'Kopie' },
                                    { value: 'docuSign', label: 'DocuSign' },
                                ]}
                                exclusive
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateSwitchSelect
                                nameOption="receivedFromAgencyAs"
                                nameDate="receivedFromAgencyAt"
                                label="Von Agentur erhalten"
                                options={[
                                    { value: 'original', label: 'Original' },
                                    { value: 'copy', label: 'Kopie' },
                                    { value: 'docuSign', label: 'DocuSign' },
                                ]}
                                exclusive
                                disabled={disabled}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={3}>
                    {isAgency && !hasParent ? (
                        <DateSwitchSelect
                            nameOption="completeness"
                            nameDate="sentBackToCustomerAt"
                            label="An Kunde zurückgeschickt"
                            options={[
                                { value: 'complete', label: 'Vollständig' },
                                { value: 'incomplete', label: 'Unvollständig' },
                            ]}
                            exclusive
                            disabled={disabled}
                        />
                    ) : (
                        <ToggleButtons
                            name="completeness"
                            options={[
                                { value: 'complete', label: 'Vollständig' },
                                { value: 'incomplete', label: 'Unvollständig' },
                            ]}
                            exclusive
                            disabled={disabled}
                        />
                    )}
                </Grid>
                {!isAgency && (
                    <Grid item xs={3}>
                        <ContractTypeSelect
                            name="contractType"
                            label="Vertragsart"
                            fullWidth
                            disabled={true}
                        />
                    </Grid>
                )}
                {!hasParent && (
                    <>
                        <Grid item xs={12}>
                            <TitleBar>Kündigen</TitleBar>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item xs={12}>
                                    <DateInput
                                        name="terminatedAt"
                                        label="Gekündigt"
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ToggleButtons
                                        name="terminatedBy"
                                        options={[
                                            { value: 'agency', label: 'Agentur' },
                                            { value: 'customer', label: 'Kunde' },
                                            { value: 'pzh', label: 'PZH' },
                                        ]}
                                        exclusive
                                        disabled={disabled}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <TerminationReasonSelect
                                name="terminationReason"
                                label="Kündigungsgrund"
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateInput
                                name="terminationConfirmationSentCustomerAt"
                                label="Bestätigung an Kunden"
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                        {isAgency && (
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        name="terminationPeriodCharged"
                                        label="Berechnung Kündigungsfrist"
                                        options={[
                                            { value: true, label: 'Ja' },
                                            { value: false, label: 'Nein' },
                                        ]}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateInput
                                        name="terminationSentAgencyAt"
                                        label="An Agentur gesendet"
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateInput
                                        name="terminationConfirmationReceivedAt"
                                        label="Eingang Bestätigung Agentur"
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                            </>
                        )}
                        {isAgency && (
                            <ConditionalField
                                conditionFieldName="terminationPeriodCharged"
                                condition
                            >
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        Hinweis: Die zu berechnende Kündigungsfrist muss in den
                                        BK-Verlauf eingetragen werden!
                                    </Alert>
                                </Grid>
                            </ConditionalField>
                        )}
                    </>
                )}
                <Grid item xs={12}>
                    <TitleBar>Notizen</TitleBar>
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name="notes"
                        label={hasParent ? 'Notizen (Addendum)' : 'Notizen'}
                        rows={3}
                        rowsMax={6}
                        multiline
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <SubmitButton container={submitRef} disabled={disabled} />
        </Form>
    );
};

ContractForm.propTypes = {
    customerId: IdPropType.isRequired,
    contractId: IdPropType,
    parentContractId: IdPropType,
    type: PropTypes.string,
    onDone: PropTypes.func.isRequired,
    submitRef: RefPropType,
};

ContractForm.defaultProps = {
    contractId: null,
    parentContractId: null,
    submitRef: null,
    type: null,
};

export default ContractForm;
