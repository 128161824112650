import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import Checkbox from '../../form/components/Checkbox';
import TextInput from '../../form/components/TextInput';
import CountrySelect from '../../form/components/specialized/CountrySelect';
import { AGENCY_RESOURCE } from '../../api/resources';
import { selectAgencyById, storeAgency, updateAgency } from '../agenciesSlice';
import { agencySchema } from '../schema';
import { useInitialValues } from '../../form/hooks';
import FormPrefiller from '../../form/components/FormPrefiller';

const AgencyForm = ({ agencyId, onDone, submitRef }) => {
    const agency = useSelector((state) => selectAgencyById(state, agencyId));

    const dispatch = useDispatch();

    const initialValues = useInitialValues(agency, {
        name: '',
        matchCode: '',
        notes: '',
        address: '',
        zip: '',
        city: '',
        country: 'pol',
        iban: '',
        bic: '',
        defaultCostFeeBasedBrake: '0.0',
        isActiveDocusign: false,
    });

    const handleSubmit = useCallback(
        (values) => {
            const prepped = { ...values, id: agencyId };
            return dispatch(agencyId ? updateAgency(prepped) : storeAgency(prepped)).then(onDone);
        },
        [dispatch, agencyId, onDone]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={agency || AGENCY_RESOURCE}
            validationSchema={agencySchema}
        >
            <FormPrefiller
                values={{
                    name: 'Tolle neue Agentur',
                    matchCode: 'Neue Agentur',
                    notes: 'Agentur-Notizen',
                    address: 'Adresse',
                    zip: '12345',
                    city: 'Hamburg',
                    country: 'pol',
                }}
            />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput name="name" label="Name" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput name="matchCode" label="Matchcode" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                name="notes"
                                label="Notizen"
                                fullWidth
                                multiline
                                rows={4}
                                rowsMax={10}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput name="address" label="Anschrift" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput name="zip" label="PLZ" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput name="city" label="Stadt" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <CountrySelect name="country" label="Land" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput name="iban" label="IBAN" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput name="bic" label="BIC" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                name="defaultCostFeeBasedBrake"
                                label="Default Fix-Kosten Pause (Kostenpflichtig)"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box ml={1}>
                                <Checkbox
                                    name="isActiveDocusign"
                                    label="DocuSign verwenden"
                                    noTurbo
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {submitRef ? (
                    <SubmitButton container={submitRef} />
                ) : (
                    <Grid item xs={12}>
                        <Box textAlign="right">
                            <SubmitButton />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Form>
    );
};

AgencyForm.propTypes = {
    agencyId: IdPropType,
    onDone: PropTypes.func,
    submitRef: RefPropType,
};

AgencyForm.defaultProps = {
    agencyId: null,
    onDone: () => null,
    submitRef: null,
};

export default AgencyForm;
