import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Select from '../Select';

const values = ['qualified', 'not_qualified'];

const QualiticationSelect = ({ name, label, disabled, fullWidth }) => {
    const { t } = useTranslation();
    const options = useMemo(
        () =>
            values.map((value) => ({
                value,
                label: t(`qualification.${value}`),
            })),
        [t]
    );
    return (
        <Select
            name={name}
            label={label}
            options={options}
            fullWidth={fullWidth}
            disabled={disabled}
            allowEmpty
        />
    );
};

QualiticationSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

QualiticationSelect.defaultProps = {
    label: null,
    disabled: false,
    fullWidth: true,
};

export default QualiticationSelect;
