import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingBox from '@pzh/crm/src/Modules/loading/components/LoadingBox';
import { useQuestionnairesByContactId } from '@pzh/crm/src/Modules/questionnaires/questionnairesSlice';
import { useContactsByCustomerId } from 'Modules/contacts/contactsSlice';
import { useApi } from 'Modules/api/components/ApiProvider';
import PdfDocumentView from '@pzh/crm/src/Modules/questionnaires/components/pdfDocumentView/PdfDocumentView';
import { pdf } from '@react-pdf/renderer';

export const CustomerLatestQuestionairePdfAttachment = ({ customerId, name, value, onChange }) => {
    const { cloud } = useApi();

    const handleChange = useMemo(
        () => (value) => {
            const newValue = value;
            onChange(name, newValue);
        },
        [onChange, name]
    );

    const [exportComplete, setExportComplete] = useState(false);
    const [exportError, setExportError] = useState(false);
    const [contacts] = useContactsByCustomerId(customerId);

    const patient = contacts.filter((c) => c.isPatient)[0];
    const primaryContact = contacts.filter((c) => c.isPrimaryContact)[0];

    const [
        questionnaires,
        { loading: questionnaireLoading, initialized: questionnaireInitialized, error: questionnaireError },
    ] = useQuestionnairesByContactId(patient?.id);

    const error = questionnaireError;
    const loading = questionnaireLoading;
    const initialized = questionnaireInitialized;
    const questionnaire = questionnaires.slice(-1)[0];

    useEffect(() => {
        const generatePdf = async () => {
            const data = {
                maincontact: primaryContact,
                patient: patient,
                questionnaire: questionnaire
            };

            const blob = await pdf(
                <PdfDocumentView
                    data={data}
                    customerId={customerId}
                    questionnaireCreatedDate={questionnaire?.createdAt}
                />
            ).toBlob();
            return blob;
        };

        const exportPdf = async () => {
            if (contacts && patient && primaryContact && questionnaire && !exportComplete) {
                try {
                    const pdfBlob = await generatePdf();
                    const upload_data = {
                        path: `/PzH/Kunden/${customerId}/`,
                        file: `questionaire_${customerId}.pdf`
                    };

                    const upload_full_path = `${upload_data.path}${upload_data.file}`;

                    // create a temp file from the Blob data => we need a temp path for the upload process
                    const temporaryFile = new File([pdfBlob], "tempfile.pdf", { type: 'application/pdf' });

                    await cloud.upload(upload_full_path, temporaryFile);
                    handleChange(upload_full_path);
                    setExportComplete(true);
                } catch (upload_error) {
                    console.error("ERROR: Uploading eb-pdf file:", upload_error);
                    setExportError(true);
                }
            }
        };

        exportPdf();
    }, [contacts, patient, primaryContact, questionnaire, exportComplete, cloud, customerId, handleChange]);

    if (error) {
        setExportError(true);
    }

    return (
        <LoadingBox loading={loading} initialized={initialized}>
            <p>Erhebungsbogen (PDF)
                {!exportError && !exportComplete && ' - Wird geladen...'}
                {!exportError && exportComplete && ' - Als Anhang bereit'}
                {exportError && ' - [Fehler beim Laden des Erhebungsbogen!]'}
            </p>
        </LoadingBox>
    );
};

CustomerLatestQuestionairePdfAttachment.propTypes = {
    customerId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

CustomerLatestQuestionairePdfAttachment.defaultProps = {};

export default CustomerLatestQuestionairePdfAttachment;