import { INVOICE_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const invoicesSlice = createResourceSlice({
    resource: INVOICE_RESOURCE,
    byKey: ['contractId', 'customerId','ContractCustomerId'],
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexInvoices,
    showInvoice,
    storeInvoice,
    updateInvoice,
    archiveInvoice,
    restoreInvoice,
    destroyInvoice,
} = invoicesSlice.actions;

export const {
    selectInvoiceById,
    makeInvoicesByIdsSelector,
    selectAllInvoiceIds,
    selectAllInvoices,
    selectInvoicesLoading,
    selectInvoicesInitialized,
    selectInvoiceLoading,
    selectInvoiceInitialized,
    selectInvoiceIdsByContractId,
    makeInvoicesByContractIdSelector,
    selectInvoiceLoadingByContractId,
    selectInvoiceInitializedByContractId,
    selectInvoiceIdsByCustomerId,
    makeInvoicesByCustomerIdSelector,
    selectInvoiceLoadingByCustomerId,
    selectInvoiceInitializedByCustomerId,
    selectInvoiceIdsByContractCustomerId,
    makeInvoicesByContractCustomerIdSelector,

} = invoicesSlice.selectors;

export const {
    useInvoices,
    useInvoice,
    useInvoicesByContractCustomerId,
    useInvoicesByContractId,
    useInvoicesByCustomerId,
} = invoicesSlice.hooks;

export default invoicesSlice.reducer;
