import { getAge } from '../../helpers/dates';
import {
    getAgeRangeLabel,
    getAides,
    getDenomination,
    getDependenceLabel,
    getFoodInfo,
    getFoodLabel,
    getGenderLabel,
    getHearingImpediment,
    getHouseholdTasksLabel,
    getHygieneLabel,
    getIncontinenceLabel,
    getLanguageSkillLabel,
    getMaritalStatus,
    getMobility,
    getNurseAccomodationFeatureLabel,
    getPKWTransmissionLabel,
    getResidentialAreaLabel,
    getSalutation,
    getSmoker,
    getSpeechImpediment,
    getStartAt,
    getToileting,
    getTranslatedSalutation,
    getVisualImpediment,
    getYesNoNull,
} from './labels';
import { formatPhoneNumber, getNested } from '../../helpers/tools';
import { insertTags } from './utils';
import { PATIENT_QUESTIONNAIRE } from './templates/questionnaire';
import { calculateFinalPrice } from '../../Modules/nurses/utils';
import Currency from '../../Modules/currencies/components/Currency';
import { formatDate } from '../../Modules/date/utils';

const getLocationPhoneNumber = (employee) => {
    const phonenumber = (employee?.phoneNumbers || [])
        .filter(
            (number) =>
                !number.type.toLowerCase().includes('fax') &&
                number.type.toLowerCase().includes('geschäftl')
        )
        .sort((number) => number.type.toLowerCase())[0];

    if (phonenumber) {
        return formatPhoneNumber(phonenumber);
    }

    return '040-2800 854-0'; // default 'Zentrale'
};

const getBasicTags = ({ customer, currentEmployee, salutation, firstname, lastname, title }) => {
    const translatedSalutation = getTranslatedSalutation(salutation);
    const translatedGreeting = getSalutation(
        salutation,
        'Sehr geehrte',
        'Sehr geehrter',
        'Sehr geehrte/r'
    );

    const phonenumber = getLocationPhoneNumber(currentEmployee);

    return [
        { tag: 'Auftragsnummer', value: `#${customer?.id}` },
        { tag: 'Begruessung', value: translatedGreeting },
        { tag: 'Anrede', value: translatedSalutation },
        { tag: 'Titel', value: title, clearEmpty: true },
        { tag: 'Vorname', value: firstname },
        { tag: 'Nachname', value: lastname },
        { tag: 'Mitarbeiter', value: `${currentEmployee?.firstname} ${currentEmployee?.lastname}` },
        { tag: 'MitarbeiterTelefon', value: phonenumber, clearEmpty: true },
        { tag: 'BkAnfrageBemerkung', value: `${customer?.contractNote}` },
    ];
};

const getNurseTags = ({ nurse, agency, nurseSuggestion, nurseDeployment }) => {
    let translatedSalutation = 'Herrn/Frau';
    let nurseFirstname = 'Vorname';
    let nurseLastname = 'Name neueste BK';
    let nursePhonenumber;

    if (nurse) {
        if (nurse.gender === 1) {
            translatedSalutation = 'Frau';
        } else if (nurse.gender === 2) {
            translatedSalutation = 'Herr';
        }

        nurseFirstname = nurse.firstname;
        nurseLastname = nurse.lastname;

        nursePhonenumber = null;
        if (nurse?.phoneNumbers && nurse?.phoneNumbers.length > 0) {
            nursePhonenumber = nurse?.phoneNumbers[0];
        }

        if (nursePhonenumber) {
            nursePhonenumber = formatPhoneNumber(nursePhonenumber);
        }
    }

    return [
        { tag: 'BkAnrede', value: translatedSalutation },
        { tag: 'BkVorname', value: nurseFirstname },
        { tag: 'BkNachname', value: nurseLastname },
        { tag: 'BkPhonenumber', value: nursePhonenumber },
        { tag: 'BkAgentur', value: agency ? agency.name : 'BK Agentur' },
        {
            tag: 'Endpreis',
            value: Currency({
                value:
                    nurseSuggestion || nurseDeployment
                        ? calculateFinalPrice({ ...(nurseSuggestion || nurseDeployment) })
                        : 'Endpreis',
                hideZeroes: false,
            }),
        },
    ];
};

const getSearchParametersTags = ({ customer }, t) => {
    // let nurseDeploymentEnd = null;
    // if (nurseDeployment) {
    //     const end = nurseDeployment.departureAt || nurseDeployment.careTill;
    //     if (end) {
    //         nurseDeploymentEnd = moment(end, DATE_FORMAT).format(DISPLAY_FORMAT);
    //     }
    // }

    return [{ tag: 'Beginn', value: getStartAt(customer?.careStartAt, customer?.icStart, t) }];
};

const getContractTags = ({ agency, agencyContract, pzhContract, nurseTransfer}) => [
    { tag: 'AgenturName', value: agency ? agency.name : 'AgenturName' },
    {
        tag: 'DlvVertragsBeginn',
        value: agencyContract ? formatDate(agencyContract.startAt) : 'DlvVertragsBeginn',
    },
    {
        tag: 'DlvVertragsEnde',
        value: agencyContract ? formatDate(agencyContract.endAt) : 'DlvVertragsEnde',
    },
    {
        tag: 'DlvGekuendigtAm',
        value: agencyContract ? formatDate(agencyContract.terminatedAt) : 'DlvGekuendigtAm',
    },
    {
        tag: 'DlvVertragspartner',
        value: agencyContract
            ? formatDate(agencyContract.contractingPartner)
            : 'DlvVertragspartner',
    },
    {
        tag: 'PzhVertragsBeginn',
        value: pzhContract ? formatDate(pzhContract.startAt) : 'PzhVertragsBeginn',
    },
    {
        tag: 'PzhVertragsEnde',
        value: pzhContract ? formatDate(pzhContract.endAt) : 'PzhVertragsEnde',
    },
    {
        tag: 'PzhGekuendigtAm',
        value: pzhContract ? formatDate(pzhContract.terminatedAt) : 'PzhGekuendigtAm',
    },
    {
        tag: 'Kuendigungsdatum',
        value: pzhContract ? formatDate(pzhContract.terminatedAt) : 'Kuendigungsdatum',
    },
    {
        tag: 'Abreise',
        value: agencyContract ? formatDate(agencyContract.startAt) : 'Abreise',
    },
    {
        tag: 'Anreisedatum',
        value: nurseTransfer ? formatDate(nurseTransfer.date) : 'Anreisedatum',
    },
];

// const getAgencyTags = ({ agency, agencyContact }) => [
//     { tag: 'AgenturName', value: agency && agency.matchCode },
//     {
//         tag: 'AgenturAnsprechpartnerAnrede',
//         value:
//             agencyContact &&
//             getSalutation(agencyContact.salutation, 'Liebe', 'Lieber', 'Liebe/Lieber'),
//     },
//     { tag: 'AgenturAnsprechpartnerVorname', value: agencyContact && agencyContact.firstname },
// ];

const getContactTags = ({ contact, prefix = '', suffix = '' }) => {
    const translatedSalutation = getTranslatedSalutation(contact.salutation);

    return [
        { tag: `${prefix}Anrede${suffix}`, value: translatedSalutation },
        { tag: `${prefix}Titel${suffix}`, value: contact.title },
        { tag: `${prefix}Vorname${suffix}`, value: contact.firstname },
        { tag: `${prefix}Nachname${suffix}`, value: contact.lastname },
    ];
};

const getPatientTags = ({ patient, questionnaire }) => {
    const aid = getNested(questionnaire, 'questionnaireData.aid');
    const aides = aid ? getAides(aid) : '';

    /*
        #6899:
        Bei 1 oder mehr Nachteinsätzen im EB, soll in der Zusammenfassung
        IMMER nur 1 Nachteinsatz angezeigt werden.
        Null Nachteinsätze im EB, sollen in der Zusammenfassung
        auch "0" angezeigt werden.
    */
    let sleepAssistanceFrequency = getNested(
        questionnaire,
        'questionnaireData.sleep.assistance.frequency'
    );
    if (sleepAssistanceFrequency === '0') {
        sleepAssistanceFrequency = '0';
    } else if (sleepAssistanceFrequency >= 1) {
        sleepAssistanceFrequency = 1;
    } else {
        sleepAssistanceFrequency = 'keine Angabe';
    }

    return [
        { tag: 'PatientAnrede', value: getTranslatedSalutation(getNested(patient, 'salutation')) },
        { tag: 'PatientTitel', value: getNested(patient, 'title'), clearEmpty: true },
        { tag: 'PatientVorname', value: getNested(patient, 'firstname') },
        { tag: 'PatientNachname', value: getNested(patient, 'lastname') },
        { tag: 'PatientPlz', value: getNested(patient, 'zip') },
        { tag: 'PatientOrt', value: getNested(patient, 'city') },
        { tag: 'PatientAlter', value: getAge(getNested(patient, 'bornAt')) || 'keine Angabe' },
        {
            tag: 'PatientGroesse',
            value: getNested(questionnaire, 'height') || 'keine Angabe',
        },
        {
            tag: 'PatientGewicht',
            value: getNested(questionnaire, 'weight') || 'keine Angabe',
        },

        {
            tag: 'PatientFamilienstand',
            value:
                getMaritalStatus(getNested(questionnaire, 'questionnaireData.maritalStatus')) ||
                'keine Angabe',
        },
        {
            tag: 'PatientKonfession',
            value:
                getDenomination(getNested(questionnaire, 'questionnaireData.denomination')) ||
                'keine Angabe',
        },
        {
            tag: 'PatientBeruf',
            value:
                getNested(questionnaire, 'questionnaireData.occupation.formerJob') ||
                'keine Angabe',
        },
        {
            tag: 'PatientHobbies',
            value:
                getNested(questionnaire, 'questionnaireData.occupation.hobbies') || 'keine Angabe',
        },
        {
            tag: 'PatientAllgemeinzustand',
            value:
                getNested(questionnaire, 'questionnaireData.mainReasonOld') &&
                'Altersbedingter reduzierter Allgemeinzustand ohne Diagnose',
            clearEmpty: true,
        },
        {
            tag: 'PatientHauptgrund',
            value: getNested(questionnaire, 'questionnaireData.mainReasonForCare'),
            clearEmpty: true,
        },
        {
            tag: 'PatientDemenz',
            value:
                getNested(questionnaire, 'questionnaireData.dementia') &&
                'Medizinisch diagnostizierte Demenz',
            clearEmpty: true,
        },
        {
            tag: 'PatientSprache',
            value:
                getSpeechImpediment(
                    getNested(
                        patient,
                        'questionnaire.questionnaireData.communication.impediments.speech'
                    )
                ) || 'keine Angabe',
        },
        {
            tag: 'PatientHoeren',
            value:
                getHearingImpediment(
                    getNested(
                        patient,
                        'questionnaire.questionnaireData.communication.impediments.hearing'
                    )
                ) || 'keine Angabe',
        },
        {
            tag: 'PatientSehen',
            value:
                getVisualImpediment(
                    getNested(
                        patient,
                        'questionnaire.questionnaireData.communication.impediments.visual'
                    ),
                    getNested(
                        patient,
                        'questionnaire.questionnaireData.communication.impediments.strengthOfVision'
                    )
                ) || 'keine Angabe',
        },
        {
            tag: 'PatientSichBewegen',
            value:
                getMobility(getNested(questionnaire, 'questionnaireData.mobility.impairments')) ||
                'keine Angabe',
        },
        {
            tag: 'PatientTransferHilfe',
            value:
                getYesNoNull(
                    getNested(questionnaire, 'questionnaireData.transfer.assistance.needed')
                ) || 'keine Angabe',
        },
        {
            tag: 'PatientTransferHaeufigkeit',
            value:
                getNested(
                    patient,
                    'questionnaire.questionnaireData.transfer.assistance.frequency'
                ) || 'keine Angabe',
        },
        {
            tag: 'PatientTransferLeicht',
            value:
                getYesNoNull(
                    getNested(questionnaire, 'questionnaireData.transfer.dependence'),
                    'some'
                ) || 'keine Angabe',
        },
        {
            tag: 'PatientLagerungHilfe',
            value:
                getYesNoNull(
                    getNested(questionnaire, 'questionnaireData.bedPositioning.needsHelp')
                ) || 'keine Angabe',
        },
        {
            tag: 'PatientLagerungHaeufigkeit',
            value:
                getNested(
                    patient,
                    'questionnaire.questionnaireData.bedPositioning.helpFrequency'
                ) || 'keine Angabe',
        },
        { tag: 'PatientHilfsmittel', value: aides || 'keine Angabe' },
        { tag: 'PatientHilfsmittelNotiz', value: aid && aid.notes, clearEmpty: true },
        {
            tag: 'PatientNachteinsaetze',
            value:
                getYesNoNull(
                    getNested(questionnaire, 'questionnaireData.sleep.assistance.needed')
                ) || 'keine Angabe',
        },
        {
            tag: 'PatientNachteinsaetzeHaeufigkeit',
            value: sleepAssistanceFrequency,
        },
        {
            tag: 'PatientNachteinsaetzeGrund',
            value:
                getNested(questionnaire, 'questionnaireData.sleep.assistance.reason') ||
                'keine Angabe',
        },
        {
            tag: 'PatientGrundpflege',
            value:
                getDependenceLabel(
                    getNested(questionnaire, 'questionnaireData.hygiene.basic'),
                    true
                ) || 'keine Angabe',
        },
        {
            tag: 'PatientGrundpflegeDetails',
            value: getNested(questionnaire, 'questionnaireData.hygiene.details'),
            clearEmpty: true,
        },
        {
            tag: 'PatientTagesgestaltung',
            value:
                getDependenceLabel(
                    getNested(questionnaire, 'questionnaireData.occupation.dependence')
                ) || 'keine Angabe',
        },
        {
            tag: 'PatientToilettengang',
            value:
                getToileting(getNested(questionnaire, 'questionnaireData.excretion.toilet')) ||
                'keine Angabe',
        },
        {
            tag: 'PatientToilettengangInfo',
            value:
                getNested(questionnaire, 'questionnaireData.excretion.details') || 'keine Angabe',
        },
        {
            tag: 'PatientInkontinenz',
            value:
                getIncontinenceLabel(
                    getNested(
                        questionnaire,
                        'questionnaireData.excretion.incontinenceCare.dependence'
                    )
                ) || 'keine Angabe',
        },
        {
            tag: 'PatientEssenTrinken',
            value:
                getFoodLabel(getNested(questionnaire, 'questionnaireData.food.diet.value')) ||
                'keine Angabe',
        },
        {
            tag: 'PatientEssenTrinkenInfo',
            value: getFoodInfo(patient),
            clearEmpty: true,
        },
    ];
};

const getAnamnesisTags = ({ questionnaire }) => [
    {
        tag: 'AnamneseHauptgrund',
        value: getNested(questionnaire, 'anamnesisData.mainReasonForCare'),
        clearEmpty: true,
    },
    {
        tag: 'AnamneseZusammenfassung',
        value: getNested(questionnaire, 'anamnesisData.meta.summary'),
        clearEmpty: true,
    },
    {
        tag: 'AnamneseUnterstuetzungInfo',
        value: getNested(questionnaire, 'anamnesisData.network.description') || 'keine Angabe',
    },
    {
        tag: 'AnamneseHygiene',
        value: getHygieneLabel(getNested(questionnaire, 'anamnesisData.hygiene')) || 'keine Angabe',
    },
    {
        tag: 'AnamneseTagesgestaltung',
        value: getNested(questionnaire, 'anamnesisData.occupation.independence') || 'keine Angabe',
    },
    {
        tag: 'AnamneseHaushalt',
        value: getNested(questionnaire, 'anamnesisData.household.independence') || 'keine Angabe',
    },
    {
        tag: 'AnamneseEssenTrinken',
        value: getNested(questionnaire, 'anamnesisData.food.notes'),
        clearEmpty: true,
    },
];

const getNurseRequestParameterTags = ({ questionnaire }) => [
    {
        tag: 'BKSucheGeschlecht',
        value:
            getGenderLabel(
                getNested(questionnaire, 'questionnaireData.nurseRequestParameters.gender')
            ) || 'keine Angabe',
    },
    {
        tag: 'BKSucheAlter',
        value:
            getAgeRangeLabel(
                getNested(questionnaire, 'questionnaireData.nurseRequestParameters.ageRange.ranges')
            ) || 'egal',
    },
    {
        tag: 'BKSucheDeutschkenntnisse',
        value:
            getLanguageSkillLabel(
                getNested(
                    questionnaire,
                    'questionnaireData.nurseRequestParameters.languageSkill.german'
                )
            ) || 'keine Präferenz',
    },
    {
        tag: 'BKSucheRauchen',
        value: getSmoker(
            getNested(questionnaire, 'questionnaireData.nurseRequestParameters.smoker')
        ),
    },
    {
        tag: 'BKSucheFuehrerschein',
        value:
            getYesNoNull(
                getNested(questionnaire, 'questionnaireData.nurseRequestParameters.driversLicense')
            ) || 'keine Angabe',
    },
    {
        tag: 'BKSuchePkw',
        value:
            getPKWTransmissionLabel(
                getNested(
                    questionnaire,
                    'questionnaireData.nurseRequestParameters.nurseData.car.transmission'
                )
            ) || 'keine Angabe',
    },
    {
        tag: 'BKSuchePkwModell',
        value:
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.nurseData.car.model'
            ) || 'keine Angabe',
    },
    {
        tag: 'BKSucheWuensche',
        value:
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.nurseData.customerNotes'
            ) || 'keine Angabe',
    },

    {
        tag: 'HaushaltWeiterePersonen',
        value:
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.additionalPeople'
            ) || 'keine Angabe',
    },
    {
        tag: 'HaushaltUnterstuetzung',
        value:
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.tasksForContacts'
            ) || 'keine Angabe',
    },
    {
        tag: 'HaushaltPersonen',
        value:
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.noOfPeople'
            ) || 'keine Angabe',
    },
    {
        tag: 'HaushaltAufgaben',
        value:
            getHouseholdTasksLabel(
                getNested(
                    questionnaire,
                    'questionnaireData.nurseRequestParameters.householdData.tasks'
                )
            ) || 'keine Angabe',
    },
    {
        tag: 'HaushaltWohnlage',
        value:
            getResidentialAreaLabel(
                getNested(
                    questionnaire,
                    'questionnaireData.nurseRequestParameters.householdData.residentialSituation'
                )
            ) || 'keine Angabe',
    },
    {
        tag: 'HaushaltAnzahlZimmer',
        value:
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.noOfRooms'
            ) || 'keine Angabe',
    },
    {
        tag: 'HaushaltWohnflaeche',
        value:
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.squareMeters'
            ) || 'keine Angabe',
    },
    {
        tag: 'HaushaltEinkaufenEntfernung',
        value:
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.shoppingDistanceOnFoot'
            ) || 'keine Angabe',
    },
    {
        tag: 'HaushaltAusstattungUnterkunft',
        value: getNurseAccomodationFeatureLabel(
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.room'
            ),
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.roomType'
            )
        ),
        clearEmpty: true,
    },
    {
        tag: 'HaushaltAusstattungMoebel',
        value: getNurseAccomodationFeatureLabel(
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.furniture'
            ),
            'furniture'
        ),
        clearEmpty: true,
    },

    {
        tag: 'HaushaltAusstattungStandards',
        value: getNurseAccomodationFeatureLabel(
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.roomLivingStandards'
            ),
            'roomLivingStandards'
        ),
        clearEmpty: true,
    },
    {
        tag: 'HaushaltAusstattungKueche',
        value: getNurseAccomodationFeatureLabel(
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.kitchen'
            ),
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.kitchenType'
            )
        ),
        clearEmpty: true,
    },
    {
        tag: 'HaushaltAusstattungBadezimmer',
        value: getNurseAccomodationFeatureLabel(
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.bathroom'
            ),
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.bathroomType'
            )
        ),
        clearEmpty: true,
    },
    {
        tag: 'HaushaltAusstattungWaschen',
        value: getNurseAccomodationFeatureLabel(
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.washing'
            ),
            'washing'
        ),
        clearEmpty: true,
    },
    {
        tag: 'HaushaltAusstattungWlan',
        value: getNurseAccomodationFeatureLabel(
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.wifi'
            ),
            'wifi'
        ),
        clearEmpty: true,
    },
    {
        tag: 'HaushaltAusstattungTransport',
        value: getNurseAccomodationFeatureLabel(
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.transport'
            ),
            'transport'
        ),
        clearEmpty: true,
    },
    {
        tag: 'HaushaltAusstattungTransportDetails',
        value: getNested(
            questionnaire,
            'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.transportDetails'
        ),
        clearEmpty: true,
    },
    {
        tag: 'HaushaltAusstattungBKInfo',
        value:
            getNested(
                questionnaire,
                'questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.notes'
            ) || 'keine Angabe',
    },
];

const findLocationProps = (customer, locations, employees) => {
    const location = locations.find((temp) => temp.id === customer.locationId);
    const mainContact = employees.find(
        (temp) => temp.locationId === location.id && temp.isPrimaryContact
    );

    return { location, mainContact };
};

const getLocationTags = ({ location, mainContact }) => {
    return [
        { tag: 'StandortName', value: location ? location.name : 'StandortName' },
        ...(mainContact
            ? [
                  ...getContactTags({ contact: mainContact, prefix: 'StandortHauptKontakt' }),
                  {
                      tag: 'StandortTelefon',
                      value: mainContact ? getLocationPhoneNumber(mainContact) : 'StandortTelefon',
                  },
                  {
                      tag: 'StandortEmail',
                      value: mainContact ? mainContact.email : 'StandortEmail',
                  },
              ]
            : []),
    ];
};

const getCustomerContactsTags = ({ contacts, extraEntities }) => {
    let patientCounter = 0;
    const tags = [];

    (contacts || []).forEach((contact) => {
        if (contact.isPatient) {
            patientCounter += 1;
            let questionnaire = null;
            if (extraEntities?.questionnaires) {
                questionnaire = extraEntities.questionnaires[contact.id] || null;
            }

            const patientTags = [
                ...getPatientTags({ contact, questionnaire }),
                ...getAnamnesisTags({ questionnaire }),
                ...getNurseRequestParameterTags({ questionnaire }),
            ];

            patientTags.forEach((tag) => {
                tags.push({ ...tag, tag: `${tag.tag}${patientCounter}` });
            });

            // templates
            tags.push({
                tag: `PatientEB${patientCounter}`,
                value: insertTags({ template: PATIENT_QUESTIONNAIRE, tags: patientTags }, true),
            });
        }

        if (contact.isContractingPartner) {
            tags.push(...getContactTags({ contact, prefix: 'Vertragspartner' }));
        }
    });

    return tags;
};

export const getTags = (
    {
        customer = null,
        currentUser = null,
        contact = null,
        contacts = null,
        locations = null,
        employees = null,
        extraEntities = null,
    },
    t
) => {
    const employees_filtered = employees?.filter(employee => employee.id === currentUser.employeeId);
    let currentEmployee = undefined;
    if (employees_filtered !== undefined) {
       currentEmployee = employees_filtered[0];
    }
    const salutation = contact ? contact.salutation : '';
    const tags = [
        ...getBasicTags({
            currentEmployee,
            customer,
            salutation,
            firstname: contact?.firstname,
            lastname: contact?.lastname,
            title: contact?.title,
        }),
        ...getSearchParametersTags({ customer }, t),
        ...getCustomerContactsTags({ contacts, extraEntities }),
    ];

    if (customer?.locationId && locations && employees) {
        tags.push(...getLocationTags(findLocationProps(customer, locations, employees)));
    }

    if (extraEntities?.tags) {
        tags.push(...extraEntities.tags);
    }

    if (extraEntities?.location) {
        tags.push(
            ...getLocationTags({
                location: extraEntities.location,
                mainContact: extraEntities?.mainContact,
            })
        );
    }

    if (extraEntities) {
        tags.push(
            ...getNurseTags({
                nurse: extraEntities?.nurse,
                agency: extraEntities?.agency,
                nurseSuggestion: extraEntities?.nurseSuggestion,
                nurseDeployment: extraEntities?.nurseDeployment,
            })
        );
        tags.push(
            ...getContractTags({
                agency: extraEntities?.agency,
                pzhContract: extraEntities?.pzhContract,
                agencyContract: extraEntities?.agencyContract,
                nurseTransfer: extraEntities?.nurseTransfer,
            })
        );
    }
    // console.log('extraEntities', extraEntities);
    // console.log('tags', tags);
    return tags;
};
