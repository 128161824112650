import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useEmailTemplate } from '../../../../../mailTemplates/emailTemplateSlice';

import { IdPropType } from '../../../../../../proptypes/basic';
import PzhContractAttachment from './PzhContractAttachment';
import AgencyContractAttachment from './AgencyContractAttachment';
import AgencyProfileAttachment from './AgencyProfileAttachment';
import {
    selectEmailTemplateAttachmentTypesById,
    useEmailTemplateAttachmentTypes,
} from '../../../../../mailTemplates/emailTemplateAttachmentTypeSlice';
import LoadingBox from '../../../../../loading/components/LoadingBox';
import { useCallbackFunc } from '../../../../../hooks';
import CustomerLatestQuestionaireCSVAttachment from '../../../../../../Modules/mails/components/send/dynamic/attachments/CustomerLatestQuestionaireCSVAttachment';
import CustomerLatestQuestionairePdfAttachment from '../../../../../../Modules/mails/components/send/dynamic/attachments/CustomerLatestQuestionairePdfAttachment';
import NurseProfileAttachment from '../../../../../../Modules/mails/components/send/dynamic/attachments/NurseProfileAttachment';

const AttachmentComponents = {
    'pzh-contract': PzhContractAttachment,
    'agency-contract': AgencyContractAttachment,
    'agency-profile': AgencyProfileAttachment,
    'customer-latest-questionaire-csv': CustomerLatestQuestionaireCSVAttachment,
    'customer-latest-questionaire-pdf': CustomerLatestQuestionairePdfAttachment,
    'nurse-profile': NurseProfileAttachment,
    'nurse-deployment': () => null, // TODO
    transfer: () => null, // TODO
};

const BaseDynamicAttachmentsSelect = ({ customerId, nurseId, templateId, onChange }) => {
    const [emailTemplate] = useEmailTemplate(templateId);
    const [values, setValues] = useState({});

    const { loading, initialized } = useEmailTemplateAttachmentTypes();
    const attachmentTypesById = useSelector(selectEmailTemplateAttachmentTypesById);

    const handleChange = useCallbackFunc(
        (name, newPath) => {
            let updatedValues;
            if (Array.isArray(newPath)) {
                updatedValues = newPath.map( (value) => value.value );
            } else {
                updatedValues = { ...values, [name]: newPath };
            }
            setValues(updatedValues);
            onChange(
                Object.values(updatedValues).map((path) => ({
                    path,
                    locked: !emailTemplate?.attachmentsDynamicEditable,
                })));
        },
        [values, onChange, emailTemplate?.attachmentsDynamicEditable]
    );

    const attachments = useMemo(
        () =>
            emailTemplate
                ? emailTemplate.attachments
                      .filter((attachment) => attachment.type === 'dynamic')
                      .map(
                          ({ emailTemplateAttachmentTypeId }) =>
                              attachmentTypesById[emailTemplateAttachmentTypeId]
                      )
                      .filter((attachmentType) => !!attachmentType)
                      .map((attachmentType) => {
                          const AttachmentComponent = AttachmentComponents[attachmentType.name];
                          const handler = (event, path) => handleChange(attachmentType.name, path);
                          return {
                              key: attachmentType.id,
                              name: attachmentType.name,
                              AttachmentComponent,
                              handler,
                          };
                      })
                : [],

        [emailTemplate, attachmentTypesById, handleChange]
    );

    return (
        <LoadingBox loading={loading} initialized={initialized}>
            <Grid container spacing={2}>
                {attachments.map(({ key, name, AttachmentComponent, handler }) => (
                    <Grid item xs={12} key={key}>
                        <AttachmentComponent
                            name={name}
                            customerId={customerId}
                            nurseId={nurseId}
                            value={values[name] || "" }
                            onChange={handler}
                        />
                    </Grid>
                ))}
            </Grid>
        </LoadingBox>
    );
};

BaseDynamicAttachmentsSelect.propTypes = {
    customerId: IdPropType.isRequired,
    templateId: IdPropType.isRequired,
    onChange: PropTypes.func.isRequired,
};

BaseDynamicAttachmentsSelect.defaultProps = {};

export default BaseDynamicAttachmentsSelect;
