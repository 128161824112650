import * as Yup from 'yup';
import moment from 'moment';
import { YupId } from '../form/schema';
import {
    NURSE_DEPLOYMENT_TYPE_CANCELLATION,
    NURSE_DEPLOYMENT_TYPE_NURSE,
    NURSE_DEPLOYMENT_TYPE_PAUSE,
    NURSE_DEPLOYMENT_TYPE_PAUSE_FEE_BASED,
} from './types';

import { checkLegalGapDays } from './helpers';

export const nurseDeploymentSchema = (nursedeployments, currentnursedeployment) => {
    return Yup.object().shape({
        careNeedId: YupId().required('Pflichtfeld'),
        customerContractId: YupId().required('Pflichtfeld'),
        customerContractEndAt: Yup.date(),
        agencyId: YupId().required('Pflichtfeld'),
        type: Yup.string()
            .oneOf([
                NURSE_DEPLOYMENT_TYPE_NURSE,
                NURSE_DEPLOYMENT_TYPE_PAUSE,
                NURSE_DEPLOYMENT_TYPE_PAUSE_FEE_BASED,
                NURSE_DEPLOYMENT_TYPE_CANCELLATION,
            ])
            .required('Pflichtfeld'),
        nurseId: YupId()
            .nullable()
            .when('type', {
                is: NURSE_DEPLOYMENT_TYPE_NURSE,
                then: YupId().nullable().required('Pflichtfeld'),
            }),
        pauseReason: Yup.string()
            .when('type', {
                is: (type) =>
                    type === NURSE_DEPLOYMENT_TYPE_PAUSE || type === NURSE_DEPLOYMENT_TYPE_PAUSE_FEE_BASED,
                then: Yup.string().required('Pflichtfeld'),
            }),
        careFrom: Yup.date()
            .nullable()
            .required('Pflichtfeld')
            .when('type', {
                is:  (type) => type === NURSE_DEPLOYMENT_TYPE_PAUSE || type === NURSE_DEPLOYMENT_TYPE_PAUSE_FEE_BASED,
                then:  Yup.date().required('Pflichtfeld')
                .test(
                    'is-date-in-use',
                    function (value) {
                        const { type, careFrom } = this.parent;
                        const result = checkLegalGapDays(nursedeployments, currentnursedeployment, careFrom, type);
                        if (!result.isValid) {
                            return this.createError({ message: result.message });
                        }
                        return true;
                    }
                ),
            })
            .when('type', {
                is:  (type) => type === NURSE_DEPLOYMENT_TYPE_CANCELLATION,
                then:  Yup.date().required('Pflichtfeld')
                .test(
                    'is-date-in-use',
                    function (value) {
                        const { type, careFrom } = this.parent;
                        const result = checkLegalGapDays(nursedeployments, currentnursedeployment, careFrom, type);
                        if (!result.isValid) {
                            return this.createError({ message: result.message });
                        }
                        return true;
                    }
                ),
            }),
        careTill: Yup.date()
            .nullable()
            .min(Yup.ref('careFrom'), 'Darf nicht vor Beginn liegen')
            .max(Yup.ref('customerContractEndAt'), 'Darf nicht nach Vertragsende liegen')
            .required('Pflichtfeld'),
        notes: Yup.string(),
        arrivalAt: Yup.date().nullable(),
        departureAt: Yup.date().nullable().min(Yup.ref('arrivalAt'), 'Darf nicht vor Ankunft liegen'),
        withoutArrTransfer: Yup.boolean().required('Pflichtfeld'),
        withoutDepTransfer: Yup.boolean().required('Pflichtfeld'),
        a1From: Yup.date().nullable(),
        a1Till: Yup.date()
            .nullable()
            .when('a1From', {
                is: (a1From) => !!a1From,
                then: Yup.date()
                    .nullable()
                    .required('Pflichtfeld')
                    .when('a1From', (a1From, schema) =>
                        schema.test(
                            'compareDates',
                            'Datum darf nicht vor Beginn sein',
                            (a1Till) =>
                                a1Till === null ||
                                !moment(a1Till).startOf('date').isBefore(moment(a1From).startOf('date'))
                        )
                    ),
            }),
        billingFrom: Yup.date().when('type', {
            is: (type) =>
                type === NURSE_DEPLOYMENT_TYPE_PAUSE || type === NURSE_DEPLOYMENT_TYPE_PAUSE_FEE_BASED,
            then: Yup.date().nullable(),
            otherwise: Yup.date()
                .typeError('Muss ein gültiges Datum sein')
                .required('Pflichtfeld')
                .min(Yup.ref('arrivalAt'), 'Darf nicht vor Ankunft liegen')
                .test(
                    'is-date-in-use',
                    function (value) {
                        const { type, billingFrom } = this.parent;
                        const result = checkLegalGapDays(nursedeployments, currentnursedeployment, billingFrom, type);
                        if (!result.isValid) {
                            return this.createError({ message: result.message });
                        }
                        return true;
                    }
                ),
        }),

        billingFromHalf: Yup.boolean().required('Pflichtfeld'),

        billingTill: Yup.date().when('type', {
            is: (type) =>
                type === NURSE_DEPLOYMENT_TYPE_PAUSE || type === NURSE_DEPLOYMENT_TYPE_PAUSE_FEE_BASED,
            then: Yup.date().nullable(),
            otherwise: Yup.date()
                .typeError('Muss ein gültiges Datum sein')
                .required('Pflichtfeld')
                .min(Yup.ref('billingFrom'), 'Darf nicht vor Berechnung von liegen'),
        }),

        billingTillHalf: Yup.boolean().required('Pflichtfeld'),
        priceAgency: Yup.number(),
        priceAgencyDaily: Yup.string().required('Pflichtfeld'),
        surchargeAgency: Yup.number().max(
            Yup.ref('priceAgency'),
            'Darf nicht Preis Agentur übersteigen'
        ),
        a1Notes: Yup.string(),
        provisionPZH: Yup.number().max(Yup.ref('priceAgency'), 'Darf nicht Preis Agentur übersteigen'),
        surchargePZH: Yup.number().max(Yup.ref('provisionPZH'), 'Darf nicht Provision PZH übersteigen'),
        provisionLocation: Yup.number().max(
            Yup.ref('provisionPZH'),
            'Darf nicht Provision PZH übersteigen'
        ),
        provisionMultiplier: Yup.number().max(
            Yup.ref('provisionPZH'),
            'Darf nicht Provision PZH übersteigen'
        ),
    })
};
