import axios from 'axios';

// Sanctum sagt, wir brauchen das?
axios.defaults.withCredentials = true;

const getCSRFCookie = () =>
    axios({
        method: 'get',
        url: '/v3/sanctum/csrf-cookie',
        baseURL: process.env.REACT_APP_API_ROOT, // FIXME: should be REACT_APP_API_AUTH
    });

const getAuthenticated = (data) =>
    axios({
        method: 'get',
        url: '/api/v3/users/me',
        baseURL: process.env.REACT_APP_API_ROOT,
    }).then((response) => ({ ...data, userV3: response.data.data }));

const authenticate = (data) =>
    data.credentials
        ? getCSRFCookie().then(() =>
              axios({
                  method: 'post',
                  url: '/v3/auth/login',
                  baseURL: process.env.REACT_APP_API_ROOT,
                  data: data.credentials,
              })
                  .then(() => getAuthenticated(data))
                  .catch((error) => {
                      if (error.response && error.response.data) {
                          throw error.response.data;
                      }
                      throw error;
                  })
          )
        : getAuthenticated(data);

const requestOTP = (data) =>
    axios({
        method: 'post',
        url: '/api/v3/requestotp',
        baseURL: process.env.REACT_APP_API_ROOT,
        data: data.credentials,
    })
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        if (error.response && error.response.data) {
            throw error.response.data;
        }
        throw error;
    });

const logout = () =>
    getCSRFCookie().then(() =>
        axios({
            method: 'post',
            url: '/v3/auth/logout',
            baseURL: process.env.REACT_APP_API_ROOT,
        })
    );

export const attachAuthentication = (api) => {
    api.onRequestOTP(requestOTP);
    api.onAuthenticate(authenticate);
    api.onLogout(logout);
};
