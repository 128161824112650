import { METHODS, RESOURCES } from './resources';
import { attachRestResources } from './rest';
import { attachAuthentication as attachAuthenticationLegacy } from './auth';
import { attachCloud } from './cloud';
import { attachAuthentication } from './authv3';
import { getEndpointUrl } from './endpoints';
import { createApi } from './api';
import { attachSocket } from './socket';

const configureApi = () => {
    const api = createApi();

    attachAuthentication(api);
    attachAuthenticationLegacy(api);

    attachRestResources(api, RESOURCES, METHODS, getEndpointUrl);

    attachCloud(api);
    attachSocket(api);

    api.onAuthenticate(({ apiKey }) => api.setApiKey(apiKey));
    api.onRequestOTP (({ apiKey }) => api.setApiKey(apiKey));
 
    api.onLogout(() => api.setApiKey(null));

    return api;
};

export default configureApi;
