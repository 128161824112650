import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LoadingBox from '../../loading/components/LoadingBox';
import Location from './Location';
import { useCustomerPatientContacts } from '../../customers/hooks';
import { IdPropType } from '../../../proptypes/basic';
import { indexLocations } from '../locationsSlice';

const LocationSuggestion = ({ customerId, zip, label, fallback, withPremiumDisplay }) => {
    const [contact] = useCustomerPatientContacts(customerId, true);
    const [initialized, setInitialized] = useState(false);
    const [isPremium, setIsPremium] = useState(false);
    const [locationId, setLocationId] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const searchZip = (contact && contact.zip) || zip;

    useEffect(() => {
        if (searchZip) {
            setLoading(true);
            dispatch(
                indexLocations({ zipCode: searchZip, status: 'active' }, null, 'locationSuggestion')
            )
                .then(({ data }) => {
                    if (data.length > 0) {
                        setIsPremium(data[0].isPremium);
                        setLocationId(data[0].id);
                    } else {
                        setLocationId(null);
                    }
                })
                .finally(() => {
                    setInitialized(true);
                    setLoading(false);
                });
        }
    }, [searchZip, dispatch, setLoading, setLocationId, setInitialized]);

    return (
        <Grid container spacing={8}>
            <Grid item xs={6}>
                {label && (
                    <Typography variant="caption" color="textSecondary">
                        {label}
                    </Typography>
                )}
                <LoadingBox loading={!initialized || loading} variant="circular" size="small">
                    {locationId ? (
                        <Location locationId={locationId} />
                    ) : (
                        <Typography variant="inherit">{fallback || 'Kein Vorschlag'}</Typography>
                    )}
                </LoadingBox>
            </Grid>
            <Grid item xs={6}>
                {withPremiumDisplay && isPremium && (
                    <LoadingBox loading={!initialized || loading} variant="circular" size="small">
                        <Typography variant="caption" color="textSecondary">
                            Type
                        </Typography>
                        <br />
                        <Typography variant="inherit">Premium</Typography>
                    </LoadingBox>
                )}
            </Grid>
        </Grid>
    );
};

LocationSuggestion.propTypes = {
    customerId: IdPropType,
    label: PropTypes.string,
    fallback: PropTypes.string,
    zip: PropTypes.string,
    withPremiumDisplay: PropTypes.bool,
};

LocationSuggestion.defaultProps = {
    customerId: null,
    label: null,
    fallback: null,
    zip: null,
    withPremiumDisplay: false,
};

export default LocationSuggestion;
