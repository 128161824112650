import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import { CardHeader } from '@material-ui/core';
import { selectLoginError } from '../selectors';
import Form from '../../form/components/Form';
import { loginSchema, otpSchema } from '../schema';
import { requestOTP, loginWithOtp } from '../actions';
import TextInput from '../../form/components/TextInput';
import ErrorMessage from '../../loading/components/ErrorMessage';
import SubmitButton from '../../form/components/SubmitButton';
import FormPrefiller from '../../form/components/FormPrefiller';
import { homePath } from '../../nav/paths';
import { Link } from 'react-router-dom';
import CountdownTimer from './CountdownTimer';

const LoginForm = () => {
    const [step, setStep] = useState('login_otprequest');
    const errors = useSelector(selectLoginError);
    const dispatch = useDispatch();
    const submitButton = React.useRef(null);

    const handleOtpRequestSubmit = (values) => {
        dispatch(requestOTP(values)).then((response) => {
            if (response.success) {
                setStep('otp');
            }
        });
    };

    const handleOtpLoginSubmit = (values) => {
        dispatch(loginWithOtp({credentials:values }))
    };

    return (
        <Form
            initialValues={step === 'login_otprequest' ? { username: '', password: '' } : { otp: '' }}
            validationSchema={step === 'login_otprequest' ? loginSchema : otpSchema}
            onSubmit={step === 'login_otprequest' ? handleOtpRequestSubmit : handleOtpLoginSubmit}
            withoutFeedback
        >
            <FormPrefiller
                onFill={(setFieldValue) => {
                    setFieldValue('username', 'admin');
                    setFieldValue('password', 'Test!1');
                }}
                focus={submitButton}
            />
            {step === 'login_otprequest' ? (
                <>
                    <Grid container justify="center" alignItems="center">
                        <Grid item sm={8}>
                            <Card>
                                <CardHeader title="Bitte melden Sie sich mit ihren Benutzerdaten an" />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextInput
                                                label="Benutzername"
                                                name="username"
                                                errors={errors}
                                                fullWidth
                                                noTurbo
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput
                                                label="Passwort"
                                                name="password"
                                                type="password"
                                                errors={errors}
                                                fullWidth
                                                noTurbo
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Grid container justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Box ml={2}>
                                                <ErrorMessage error={errors} />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <SubmitButton innerRef={submitButton} label="Login" />
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container justify="center" alignItems="center">
                        <Grid item sm={8}>
                            <Card>
                                <CardHeader title="Bitte geben Sie den per E-Mail erhaltene 6-stelligen Verifizierungs-Code ein:" />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <input type="hidden" name="nothing" /> { /* Workaround for Chrome autofill issue */}
                                            <TextInput
                                                autocomplete="one-time-code"
                                                label="Verifizierungs-Code "
                                                name="otp"
                                                errors={errors}
                                                fullWidth
                                                noTurbo
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Box ml={2}>
                                            <CountdownTimer initialTime={5*60*1000} info="Verbleibende Zeit:" /> 
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Box ml={2}>
                                                <ErrorMessage error={errors} />
                                            </Box>
                                        </Grid>
                                        <Grid item container spacing={2}>
                                            <Grid item>
                                                <Link to={homePath}>
                                                    ZURÜCK
                                                </Link>
                                            </Grid>
                                            <Grid item>
                                                <SubmitButton innerRef={submitButton} label="Bestätigen" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            )}
        </Form>
    );
};

export default LoginForm;
