import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import ResourceSearch from '../../search/components/ResourceSearch';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { CONTRACT_RESOURCE, CUSTOMER_RESOURCE } from '../../api/resources';
import LocationSelect from '../../form/components/specialized/LocationSelect';
import CustomerLink from '../../customers/components/CustomerLink';
import CustomerNames from '../../customers/components/CustomerNames';
import Location from '../../locations/components/Location';
import DisplayDate from '../../date/components/DisplayDate';
import ActiveAgencies from '../../agencies/components/ActiveAgencies';
import Select from '../../form/components/Select';
import { useUserEmployee } from '../../auth/hooks';
import { useAssignActionNotification } from '../hooks';
import { useNoteAction } from '../../notes/hooks';
import TitleBar from '../../layout/components/TitleBar';
import InterestedStatusOverview from '../../interestedCustomer/components/InterestedStatusOverview';

const getSearchFields = ({ employee }) => [
    {
        name: 'locationId',
        label: 'Standorte',
        component: LocationSelect,
        allowEmpty: true,
        xs: 3,
    },
    {
        name: 'team',
        label: 'Team',
        component: Select,
        initial: (employee && employee.team) || 'teamNK',
        xs: 3,
    },
];
const prepareSearch = (values) => {
    if (values.team === 'teamNK') {
        return {
            ...values,
            employeeId: 'NULL',
            employeeFormerId: 'NULL',
            currentType: ['prospective', 'contract'],
        };
    }

    return {
        ...values,
        employeeId: 'NULL',
        employeeFormerId: 'NOT_NULL',
        currentType: [
            'prospective',
            'contract',
            'nurse_deployed',
            'pause_short',
            'pause_long',
            'terminated_agency',
        ],
    };
};

const columns = [
    {
        key: 'id',
        label: 'Nr.',
        component: ({ id }) => <CustomerLink customerId={id}>{id}</CustomerLink>,
    },
    {
        key: 'customer',
        accessor: 'id',
        as: 'customerId',
        label: 'Kunde',
        component: CustomerNames,
    },
    {
        key: 'locationId',
        label: 'Standort',
        component: Location,
    },
    {
        key: 'status',
        accessor: 'id',
        as: 'customerId',
        label: 'Status',
        component: InterestedStatusOverview,
    },
    {
        key: 'agencies',
        accessor: 'id',
        as: 'customerId',
        label: 'Agentur',
        component: ActiveAgencies,
    },
    {
        key: 'isPremium',
        label: 'Premium',
        component: ({ isPremium }) =>
            isPremium ? (
                <span
                    style={{
                        fontWeight: '500',
                        color: '#a2bb0c',
                    }}
                >
                    Premium
                </span>
            ) : (
                <span />
            ),
    },
    {
        key: 'vipContactId',
        label: 'VIP',
        component: ({ vipContactId, data: { employeeId, createdAt } }) =>
            vipContactId ? (
                <span
                    style={{
                        fontWeight: '500',
                        color: 'red',
                    }}
                >
                    VIP
                </span>
            ) : (
                <span />
            ),
    },
    {
        key: 'employeeAssignedAt',
        label: 'Zuzuweisen seit',
        component: ({ employeeAssignedAt, data: { employeeId, createdAt } }) =>
            employeeId ? (
                <Typography variant="body2" color="textSecondary">
                    Zugewiesen
                </Typography>
            ) : (
                <DisplayDate value={employeeAssignedAt || createdAt} />
            ),
    },
];

const UnassignedCustomers = () => {
    const listId = 'customer.UnassignedCustomers';

    const { employee } = useUserEmployee();
    const searchFields = useMemo(() => getSearchFields({ employee }), [employee]);

    const noteAction = useNoteAction();
    const assignAction = useAssignActionNotification();
    const extraActions = useMemo(() => [noteAction, assignAction], [noteAction, assignAction]);

    return (
        <>
            <TitleBar mt={2}>Zuzuweisende Kunden</TitleBar>
            <ResourceSearch listId={listId} fields={searchFields} prepare={prepareSearch} persist />
            <ResourceTable
                listId={listId}
                columns={columns}
                resource={CUSTOMER_RESOURCE}
                showActionsInline={3}
                with={{ customerContracts: CONTRACT_RESOURCE }}
                actions={extraActions}
            />
        </>
    );
};

UnassignedCustomers.propTypes = {};

UnassignedCustomers.defaultProps = {};

export default UnassignedCustomers;
