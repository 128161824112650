import * as Yup from 'yup';
import { phoneNumberSchema } from '../phonenumbers/schema';
import { checkLegalGapDays } from '../nurseDeployments/helpers';

const isTransferDateValid = (status, travelType, billingDateArrival, billingDateDeparture) => {

    //console.log("isTransferDateValid",status, travelType,billingDateArrival, billingDateDeparture)
    if (status === 'details_agency_requested') // no data needed in that status
        return true;

    if (travelType === 'arrival') {
        if (billingDateDeparture === null || billingDateDeparture === undefined)
            return true;
        else
            return new Date(billingDateArrival) < new Date(billingDateDeparture);
    } else {
         if (billingDateArrival === null || billingDateArrival === undefined)
            return true;
        else
            return new Date(billingDateArrival) < new Date(billingDateDeparture);
    }
};

export const transferFormSchema = (travelType, billingDateArrival, billingDateDeparture, nursedeployments, currentnursedeployment) => {
     return Yup.object().shape({
        status: Yup.string(),
        details_agency_received_date: Yup.date().nullable(),
        details_agency_requested_date: Yup.date().nullable(),
        details_complete_date: Yup.date().nullable(),
        details_sent_agency_date: Yup.date().nullable(),
        details_sent_customer_date: Yup.date().nullable(),
        waiting_for_customer_date: Yup.date().nullable(),
        date: Yup.date()
                .nullable()
                .when('status', {
                    is: (status) => status !== 'details_agency_requested' && status !== undefined,
                    then: Yup.date().required('Pflichtfeld')
                         .test(
                            'is-transfer-date-valid',
                            'Ankunft muss vor Abreise liegen',
                            function (value) {
                                const { status } = this.parent;
                                const transferDateArrival = travelType === 'arrival' ? value : billingDateArrival;
                                const transferDateDeparture = travelType !== 'arrival' ? value : billingDateDeparture;
                                return isTransferDateValid(status, travelType, transferDateArrival, transferDateDeparture);
                            }
                        )
                        .test(
                            'is-date-in-use',
                            function (value) {
                                if (travelType !== 'arrival')
                                    return true;
                                const result = checkLegalGapDays(nursedeployments, currentnursedeployment, value, currentnursedeployment?.type);
                                if (!result.isValid) {
                                    return this.createError({ message: result.message });
                                }
                                return true;
                            }
                        ),
                }),
        time: Yup.string().max(255),
        meansOfTravel: Yup.string(),
        meansOfTravelDetails: Yup.string().max(255),
        transferRequired: Yup.bool().required('Pflichtfeld'),
        destination: Yup.string(),
        destinationName: Yup.string().max(255),
        destinationAddress: Yup.string().max(255),
        destinationZip: Yup.string().max(255),
        destinationCity: Yup.string().max(255),

        pickup: Yup.string(),
        pickupDetails: Yup.string().max(255),
        phonenumberName: Yup.string().max(255),
        phoneNumbers: Yup.array().when(['transferRequired', 'pickup'], {
            is: (transferRequired, pickup) => {
                if (transferRequired === true && pickup && pickup !== 'taxi') return true;
                else return false;
            },
            then: Yup.array().of(phoneNumberSchema),
            otherwise: Yup.array().nullable(),
        }),
    })};
