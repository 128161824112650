// Note: Export JSON to CSV
import {
  RADIO_NO_YES,
  SELECT_MARITAL_STATUS,
  RADIO_DEMENTIA_LISTENS_TO,
  SELECT_SALUTATION,
  SELECT_DEMENTIA_TYPES,
  RADIO_YES_NO_UNCLEAR,
  CHECK_MUSCULOSKELETAL,
  CHECK_NEUROLOGICAL,
  CHECK_CARDIOVASCULAR,
  CHECK_GASTRODUODENAL,
  CHECK_RESPIRATORY,
  CHECK_CURRENT_PROBLEMS,
  CHECK_OTHER_ILLNESSES,
  CHECK_REST_AND_SLEEP,
  CHECK_MOBILITY,
  RADIO_SLEEP_INTERRUPTIONS,
  RADIO_HELP_NEEDED,
  RADIO_TRANSFER,
  RADIO_DEPENDENCY_LEVELS_EXTENDED,
  CHECK_FOOD_DIET,
  RADIO_FOOD_FLUID_INTAKE,
  RADIO_EXCRETION,
  CHECK_EXCRETION_OTHER,
  RADIO_NO_SOMETIMES_ALWAYS,
  RADIO_INCONTINENCE_CARE,
  CHECK_BREATHING_IMPEDIMENTS,
  RADIO_SAFETY_CONSCIOUSNESS,
  RADIO_SAFETY_MEDICATION_INTAKE,
  RADIO_NONE_SOMETIMES_ALWAYS,
  RADIO_DEPENDENCY_LEVELS,
  RADIO_YES_NO,
  CHECK_CURRENT_THERAPIES,
  RADIO_LANGUAGES,
  CHECK_SPEECH_IMPEDIMENTS,
  CHECK_HEARING_IMPEDIMENTS,
  CHECK_VISUAL_IMPEDIMENTS,
  CHECK_MENTAL_STATE,
  RADIO_ZERO_TO_TEN,
  CHECK_AIDS_IN_HOUSEHOLD,
  CHECK_HOUSEHOLD_TASKS,
  RADIO_NO_OF_PEOPLE,
  CHECK_HOUSEHOLD_WISHES,
  CHECK_HOUSEHOLD_CLEANING,
  RADIO_RESIDENTIAL_SITUATION,
  NURSE_ACCOMODATION,
  RADIO_GENDER,
  CHECK_LANGUAGE_SKILL,
  CHECK_AGE,
  CHECK_QUALIFICATION,
  RADIO_DENOMINATION,
  RADIO_DEMENTIA_RESTRICTIONS_PEOPLE,
  RADIO_DEMENTIA_RESTRICTIONS_MOTOR,
} from "../labelValues";

import { getNurseAccomodationFeatureDetail } from "../../../config/wizard/labels";

import { formatDate } from "../../../helpers/tools";
import { isString } from "lodash";

export const findElementLabel = (label, elements) => {
  //console.log("findElementLabel", label);
  if (!label) {
    return "-";
  }
  const element = elements.find((element) => element.value === label);
  return element ? element.label : "-";
}

export const getProperOutput = (output) =>
{
  if (output == null || output === undefined) {
    return "-";
  }
  if (isString(output) && output.length === 0) {
    return "-";
  }
  return output;
}

export const ExportLatestQuestionaireDataJsonToCsv = (patient, questionnaire, maincontact, delimiter) => {

  //console.log("ExportLatestQuestionaireDataJsonToCsv",patient, questionnaire, maincontact, delimiter);

  if (delimiter == null || delimiter === "" || delimiter.length > 1) {
    delimiter = ";";
  }

  if (patient == null || patient === undefined) {
    throw new Error(
      "Invalid or empty patient array passed to ExportLatestQuestionaireDataJsonToCsv(patient, questionnaire, maincontact, delimiter)."
    );
  }
  if (questionnaire == null || questionnaire === undefined) {
    throw new Error(
      "Invalid or empty questionnaire array passed to ExportLatestQuestionaireDataJsonToCsv(patient, questionnaire, maincontact, delimiter)."
    );
  }
  if (maincontact == null || maincontact === undefined) {
    throw new Error(
      "Invalid or empty maincontact array passed to ExportLatestQuestionaireDataJsonToCsv(patient, questionnaire, maincontact, delimiter)."
    );
  }
  const resultDictionary = {};

  //
  // MainCustomerContactPage
  //
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Anrede"
  resultDictionary["1"] = findElementLabel(maincontact?.salutation, SELECT_SALUTATION);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Titel"
  resultDictionary["2"] = getProperOutput(maincontact?.title);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Beziehungsverhältnis"
  resultDictionary["3"] = findElementLabel(maincontact?.title, SELECT_MARITAL_STATUS);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Vorname"
  resultDictionary["4"] = getProperOutput(maincontact?.firstname);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Nachname"
  resultDictionary["5"] = getProperOutput(maincontact?.lastname);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Staße und Hausnummer"
  resultDictionary["6"] = getProperOutput(maincontact?.address);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Land"
  resultDictionary["7"] = getProperOutput(maincontact?.country);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Postleitzahl"
  resultDictionary["8"] = getProperOutput(maincontact?.zip);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Stadt
  resultDictionary["9"] = getProperOutput(maincontact?.city);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - EMail
  resultDictionary["10"] = getProperOutput(maincontact?.email);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Phone Number Type
  resultDictionary["11"] = getProperOutput(maincontact?.phoneNumbers[0]?.type);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Phone Number Vorwahl
  resultDictionary["12"] = getProperOutput(maincontact?.phoneNumbers[0]?.countryCode);
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Phone Number Prefix+Number
  resultDictionary["13"] = getProperOutput(maincontact?.phoneNumbers[0]?.prefix + maincontact?.phoneNumbers[0]?.number);

  //
  // PatientContactPage
  //
  // Kontaktdaten der zu betreuenden Person - Anrede
  resultDictionary["14"] = findElementLabel(patient?.salutation, SELECT_SALUTATION);
  // Kontaktdaten der zu betreuenden Person - Anrede
  resultDictionary["15"] = getProperOutput(patient?.title);
  // Kontaktdaten der zu betreuenden Person - Vorname
  resultDictionary["16"] = getProperOutput(patient?.firstname);
  // Kontaktdaten der zu betreuenden Person - Nachname
  resultDictionary["17"] = getProperOutput(patient?.lastname);
  // Kontaktdaten der zu betreuenden Person - Straße
  resultDictionary["18"] = getProperOutput(patient?.address);
  // Kontaktdaten der zu betreuenden Person - Postleitzahl
  resultDictionary["19"] = getProperOutput(patient?.zip);
  // Kontaktdaten der zu betreuenden Person - Stadt
  resultDictionary["20"] = getProperOutput(patient?.city);
  // Kontaktdaten der zu betreuenden Person - EMail
  resultDictionary["21"] = getProperOutput(patient?.email);
  // Kontaktdaten der zu betreuenden Person - Geburtsdatum
  resultDictionary["22"] = formatDate(patient?.bornAt);
  // Kontaktdaten der zu betreuenden Person - Telefonnummer Type
  resultDictionary["23"] = getProperOutput(patient?.phoneNumbers[0]?.type);
  // Kontaktdaten der zu betreuenden Person - Phone Number Vorwahl
  resultDictionary["24"] = getProperOutput(patient?.phoneNumbers[0]?.countryCode);
  // Kontaktdaten der zu betreuenden Person - Phone Number Prefix+Number
  resultDictionary["25"] = getProperOutput(patient?.phoneNumbers[0]?.prefix + patient?.phoneNumbers[0]?.number);

  //
  // Anamnese Allgemein
  //
  // Anamnese Allgemein - Größe (in cm)
  resultDictionary["26"] = getProperOutput(questionnaire?.height);
  // Anamnese Allgemein - Körpergewicht (in kg)
  resultDictionary["27"] = getProperOutput(questionnaire?.weight);
  // Anamnese Allgemein - Raucher/in
  resultDictionary["28"] = findElementLabel(questionnaire?.questionnaireData?.smoker, RADIO_NO_YES);
  // Anamnese Allgemein - Familienstand
  resultDictionary["29"] = findElementLabel(questionnaire?.questionnaireData?.maritalStatus, SELECT_MARITAL_STATUS);
  // Anamnese Allgemein - Konfession
  resultDictionary["30"] = findElementLabel(questionnaire?.questionnaireData?.denomination?.value, RADIO_DENOMINATION);
  // Anamnese Allgemein - Konfession - Welche andere
  resultDictionary["31"] = getProperOutput(questionnaire?.questionnaireData?.denomination?.detail);


  // Anamnese Allgemein - Krankenkasse
  resultDictionary["32"] = getProperOutput(questionnaire?.insuranceCompany);
  // Anamnese Allgemein - Pflegegrad
  resultDictionary["33"] = getProperOutput(questionnaire?.nursingLevel);
  // Anamnese Allgemein - Höherstufung beantrag
  resultDictionary["34"] = findElementLabel(questionnaire?.upgradeRequested, RADIO_NO_YES);
  // Anamnese Allgemein - Vorsorgevollmacht vorhanden?
  resultDictionary["35"] = findElementLabel(questionnaire?.powerOfAttorneyExists, RADIO_NO_YES);
  // Anamnese Allgemein - Patientenverfügung vorhanden?
  resultDictionary["36"] = findElementLabel(questionnaire?.patientDecreeExists, RADIO_NO_YES);
  // Anamnese Allgemein - Wo werden diese aufbewahrt
  resultDictionary["37"] = getProperOutput(questionnaire.locationOfDocuments);

  //
  // DementiaPage
  //
  // Demenzbetreuung - Besteht eine demenzielle Erkrankung?
  resultDictionary["38"] = findElementLabel(questionnaire?.questionnaireData?.dementia, RADIO_NO_YES);
  // Demenzbetreuung - Um welchen Typ der Demenz handelt es sich?
  resultDictionary["39"] = findElementLabel(questionnaire?.dementiaData?.type, SELECT_DEMENTIA_TYPES);
  // Demenzbetreuung - Seit wann bsteht die demenzielle Erkrankung=
  resultDictionary["40"] = getProperOutput(questionnaire?.dementiaData?.since);
  // Demenzbetreuung - Wie gestaltet sich die derzeitige Therapie?
  resultDictionary["41"] = getProperOutput(questionnaire?.dementiaData?.currentTherapy);
  // Demenzbetreuung - Demenz wurde ärztlich diagnostiziert?
  resultDictionary["42"] = findElementLabel(questionnaire?.dementiaData?.diagnosedByProfessional, RADIO_NO_YES);


  //
  // Koknitive / kommunikative Fähigkeiten und Defizite
  //
  // Erkennen von Personen aus dem näheren Umfeld
  resultDictionary["43"] = findElementLabel( questionnaire?.dementiaData?.restrictions?.recognizingPeople, RADIO_DEMENTIA_RESTRICTIONS_PEOPLE );
  // Örtliche Orientierung
  resultDictionary["44"] = findElementLabel( questionnaire?.dementiaData?.restrictions?.positionalOrientation, RADIO_DEMENTIA_RESTRICTIONS_PEOPLE );
  // Zeitliche Orientierung
  resultDictionary["45"] = findElementLabel( questionnaire?.dementiaData?.restrictions?.temporalOrientation, RADIO_DEMENTIA_RESTRICTIONS_PEOPLE );
  // Erinnern an wesentliche Ereignisse oder Beobachtungen, die kürzlich erfolgten
  resultDictionary["46"] = findElementLabel( questionnaire?.dementiaData?.restrictions?.rememberingEvents, RADIO_DEMENTIA_RESTRICTIONS_PEOPLE );
  // Steuern von mehrschrittigen Alltagshandlungen¹
  resultDictionary["47"] = findElementLabel( questionnaire?.dementiaData?.restrictions?.controlOfMundaneTasks, RADIO_DEMENTIA_RESTRICTIONS_PEOPLE );
  // Treffen von adäquaten Entscheidungen im Alltag
  resultDictionary["48"] = findElementLabel( questionnaire?.dementiaData?.restrictions?.mundaneDecisionMaking, RADIO_DEMENTIA_RESTRICTIONS_PEOPLE );
  // Verstehen von Sachverhalten und Informationen
  resultDictionary["49"] = findElementLabel( questionnaire?.dementiaData?.restrictions?.comprehension, RADIO_DEMENTIA_RESTRICTIONS_PEOPLE );
  // Erkennen von Risiken und Gefahren
  resultDictionary["50"] = findElementLabel( questionnaire?.dementiaData?.restrictions?.riskAssessment, RADIO_DEMENTIA_RESTRICTIONS_PEOPLE );
  // Mitteilen von elementaren Bedürfnissen
  resultDictionary["51"] = findElementLabel( questionnaire?.dementiaData?.restrictions?.communicatingElementaryNeeds, RADIO_DEMENTIA_RESTRICTIONS_PEOPLE );
  // Verstehen und Umsetzen von Aufforderungen
  resultDictionary["52"] = findElementLabel( questionnaire?.dementiaData?.restrictions?.understandingAndFollowingRequests, RADIO_DEMENTIA_RESTRICTIONS_PEOPLE );
  // Beteiligen an einem Gespräch
  resultDictionary["53"] = findElementLabel( questionnaire?.dementiaData?.restrictions?.conversationParticipation, RADIO_DEMENTIA_RESTRICTIONS_PEOPLE );



  //
  // Verhaltensweisen und psychische Problemlagen
  //
  // Motorische Verhaltensauffälligkeiten
  resultDictionary["54"] = findElementLabel(questionnaire?.dementiaData?.problems?.motorProblems, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Nächtliche Unruhe
  resultDictionary["55"] = findElementLabel(questionnaire?.dementiaData?.problems?.nightlynrest, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Verstecken, sammeln, verlegen von Gegenständen und/oder Geld
  resultDictionary["56"] = findElementLabel(questionnaire?.dementiaData?.problems?.hidingCollectingMisplacingObjectsOrMoney, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Beschädigen von Gegenständen
  resultDictionary["57"] = findElementLabel(questionnaire?.dementiaData?.problems?.damagingObjects, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Körperlich aggressives Verhalten - schlagen (auch sich selbst), beißen, kratzen, kneifen
  resultDictionary["58"] = findElementLabel(questionnaire?.dementiaData?.problems?.physicallyAggressiveBehaviour, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Verbale Aggression
  resultDictionary["59"] = findElementLabel(questionnaire?.dementiaData?.problems?.verballyAggressiveBehaviour, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Andere pflegerelevante auffällige Lautäußerungen
  resultDictionary["60"] = findElementLabel(questionnaire?.dementiaData?.problems?.otherRelevantVocalizations, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Abwehr pflegerischer und anderer unterstützender Maßnahmen
  resultDictionary["61"] = findElementLabel(questionnaire?.dementiaData?.problems?.defenseOfSupportiveMeasures, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Ängste
  resultDictionary["62"] = findElementLabel(questionnaire?.dementiaData?.problems?.fears, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Antriebslosigkeit
  resultDictionary["63"] = findElementLabel(questionnaire?.dementiaData?.problems?.listlessness, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Sozial inadäquate Verhaltensweisen
  resultDictionary["64"] = findElementLabel(questionnaire?.dementiaData?.problems?.sociallyInadequateBehaviour, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Suchterkrankungen (Medikamente, Alkohol etc.)
  resultDictionary["65"] = findElementLabel(questionnaire?.dementiaData?.problems?.addictions, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Sexuelle körperliche Annäherungsversuche
  resultDictionary["66"] = findElementLabel(questionnaire?.dementiaData?.problems?.sexualPhysicalOvertures, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);
  // Unangemessenes An- und Ausziehen
  resultDictionary["67"] = findElementLabel(questionnaire?.dementiaData?.problems?.inappropriateChangingOfClothes, RADIO_DEMENTIA_RESTRICTIONS_MOTOR);

  //
  // Für Sicherheit sorgen
  //
  // Hinlauftendenz (Weglauftendenz)
  resultDictionary["68"] = findElementLabel(questionnaire?.dementiaData?.wanderingTendency, RADIO_YES_NO_UNCLEAR);
  // Sicherung des Hauses/der Wohnung
  resultDictionary["69"] = findElementLabel(questionnaire?.dementiaData?.securityHouseApartment?.value, RADIO_NO_YES);
  // Sicherung der Umgebung
  resultDictionary["70"] = findElementLabel(questionnaire?.dementiaData?.securityEnvironment?.value, RADIO_NO_YES);
  // Sicherung der Hauses/der Wohnung - Wie
  resultDictionary["71"] = getProperOutput(questionnaire?.dementiaData?.securityHouseApartment?.detail);
  // Sicherung der Umgebung - Wie
  resultDictionary["72"] = getProperOutput(questionnaire?.dementiaData?.securityEnvironment?.detail);

  //
  // Zusätzliche Fragen
  //
  // Gut anleitbar zur Durchführung der Körperpflege?
  resultDictionary["73"] = findElementLabel(questionnaire?.dementiaData?.goodToGuideToHygiene, RADIO_YES_NO_UNCLEAR);
  // Gut anleitbar bei der Bewältigung des Alltags?
  resultDictionary["74"] = findElementLabel(questionnaire?.dementiaData?.goodToGuideToEverydayLife, RADIO_YES_NO_UNCLEAR);
  // Ritual oder eine Medikation bei Unruhezuständen?
  resultDictionary["75"] = findElementLabel(questionnaire?.dementiaData?.ritualOrMedicationForRestlessness?.value, RADIO_YES_NO_UNCLEAR);
  // Wenn ja, welches/welche??
  resultDictionary["76"] = getProperOutput(questionnaire?.dementiaData?.ritualOrMedicationForRestlessness?.detail);
  // Was passiert bei Ablehnung/ablehnendem Verhalten?
  resultDictionary["77"] = getProperOutput(questionnaire?.dementiaData?.behaviourWhenRejected);
  // Die betroffene Person reagiert vorwiegend auf den
  resultDictionary["78"] = questionnaire?.dementiaData?.listensTo?.value === "nickname" ? `Spitznamen, ${questionnaire?.dementiaData?.listensTo?.detail}`
                        : findElementLabel(questionnaire?.dementiaData?.listensTo?.value, RADIO_DEMENTIA_LISTENS_TO);
  // Wichtige Hinweise zur Tagesstruktur und Kooperationsbereitschaft
  resultDictionary["79"] = getProperOutput(questionnaire?.dementiaData?.dailyStructureAndCooperationNotes);


  //
  // Hauptgrund für die Betreuungsbedürftigkeit
  //
  // Altersbedingter reduzierter Allgemeinzustand ohne Diagnose?
  resultDictionary["80"] = findElementLabel(questionnaire?.questionnaireData?.mainReasonOld, RADIO_NO_YES);
  // Andere Gründe / Details
  resultDictionary["81"] = getProperOutput(questionnaire?.questionnaireData?.mainReasonForCare);

  //
  // Weitere Diagnosen
  //
  // Amputationen
  resultDictionary["82"] = getProperOutput(questionnaire?.questionnaireData?.amputations);
  // Lähmungen
  resultDictionary["83"] = getProperOutput(questionnaire?.questionnaireData?.paralyses);
  // Bewegungsapparat
  resultDictionary["84"] = getProperOutput(questionnaire?.questionnaireData?.musculoskeletal
                          ? questionnaire?.questionnaireData?.musculoskeletal?.map((elem) => findElementLabel(elem, CHECK_MUSCULOSKELETAL)).join(" | ")
                          : "-");
  // Neurologische Erkrankungen
  resultDictionary["85"] = getProperOutput(questionnaire?.questionnaireData?.neurological
                         ? questionnaire?.questionnaireData?.neurological.value?.map((elem) => findElementLabel(elem, CHECK_NEUROLOGICAL)).join(" | ")
                         : "-");
  // Herzkreislaufsystem
  resultDictionary["86"] = getProperOutput(questionnaire?.questionnaireData?.cardiovascular
                          ? questionnaire?.questionnaireData?.cardiovascular?.map((elem) => findElementLabel(elem, CHECK_CARDIOVASCULAR)).join(" | ")
                          : "-");
  // Magen-Darm-Trakt
  resultDictionary["87"] = getProperOutput(questionnaire?.questionnaireData?.gastroduodenal
                        ? questionnaire?.questionnaireData?.gastroduodenal?.map((elem) => findElementLabel(elem, CHECK_GASTRODUODENAL)).join(" | ")
                        : "-");
  // Atemwege
  resultDictionary["88"] = getProperOutput(questionnaire?.questionnaireData?.respiratory ? questionnaire?.questionnaireData?.respiratory?.map((elem) => findElementLabel(elem, CHECK_RESPIRATORY)).join(" | ") : "-");

  // Aktuelle Probleme
  resultDictionary["89"] = getProperOutput(questionnaire?.questionnaireData?.currentProblems
                        ? questionnaire?.questionnaireData?.currentProblems?.map((elem) => findElementLabel(elem, CHECK_CURRENT_PROBLEMS)).join(" | ")
                        : "-");
  // Sonstige
  resultDictionary["90"] = getProperOutput(questionnaire?.questionnaireData?.otherIlnesses ? questionnaire?.questionnaireData?.otherIlnesses?.map((elem) => findElementLabel(elem, CHECK_OTHER_ILLNESSES)).join(" | ") : "-");
  // Seit wann besteht die Krankheit, ist diese konstant oder verändert sich der Zustand?
  resultDictionary["91"] = getProperOutput(questionnaire?.questionnaireData?.since);
  // ansteckende / übertragbare Krankheiten
  resultDictionary["92"] = findElementLabel(questionnaire?.questionnaireData?.contageous?.value, RADIO_NO_YES )
  // ansteckende / übertragbare Krankheiten - Details
  resultDictionary["93"] = getProperOutput(questionnaire?.questionnaireData?.contageous?.detail);

  //
  // Ruhen und Schlafen
  //
  // Beeinträchtigungen
  resultDictionary["94"] = getProperOutput(questionnaire?.questionnaireData?.sleep?.impairments ? questionnaire?.questionnaireData?.sleep?.impairments?.map((elem) => findElementLabel(elem, CHECK_REST_AND_SLEEP)).join(" | ") : "-");
  // Nachtruhe von ca
  resultDictionary["95"] = getProperOutput(questionnaire?.questionnaireData?.sleep?.from);
  // Nachtruhe bis ca.
  resultDictionary["96"] = getProperOutput(questionnaire?.questionnaireData?.sleep?.to);
  // Steht häufig nachts auf?
  resultDictionary["97"] = findElementLabel(questionnaire?.questionnaireData?.sleep?.interruptions, RADIO_SLEEP_INTERRUPTIONS);
  // Nächtliche Einsätze der Betreuungskraft sind notwendig
  resultDictionary["98"] = findElementLabel(questionnaire?.questionnaireData?.sleep?.assistance?.needed, RADIO_NO_YES);
  // Nächtliche Einsätze - Wie viele?
  resultDictionary["99"] = getProperOutput(questionnaire?.questionnaireData?.sleep?.assistance?.frequency);
  // Gründe
  resultDictionary["100"] = getProperOutput(questionnaire?.questionnaireData?.sleep?.assistance?.reason);

  //
  // Sich bewegen
  //
  // Sich bewegen
  resultDictionary["101"] = getProperOutput(questionnaire?.questionnaireData?.mobility?.impairments ? questionnaire?.questionnaireData?.mobility?.impairments?.map((elem) => findElementLabel(elem, CHECK_MOBILITY)).join(" | ") : "-");
  // Hilfe beim Transfer benötigt?
  resultDictionary["102"] = findElementLabel(questionnaire?.questionnaireData?.transfer?.assistance?.needed, RADIO_HELP_NEEDED);
  // Hilfe beim Transfer - Häufigkeit
  resultDictionary["103"] = getProperOutput(questionnaire?.questionnaireData?.transfer?.assistance?.frequency);
  // Hilfe beim Transfer - Schwierigkeitsgrad
  resultDictionary["104"] = findElementLabel(questionnaire?.questionnaireData?.transfer?.dependence, RADIO_TRANSFER);
  // Hilfe bei der Lagerung im Bett benötigt
  resultDictionary["105"] = findElementLabel(questionnaire?.questionnaireData?.bedPositioning?.needsHelp, RADIO_HELP_NEEDED);
  // Hilfe bei der Lagerung im Bett - Häufigkeit
  resultDictionary["106"] = getProperOutput(questionnaire?.questionnaireData?.bedPositioning?.helpFrequency);
  // Hilfe bei der Lagerung im Bett - Schwierigkeitsgrad
  resultDictionary["107"] = findElementLabel(questionnaire?.questionnaireData?.bedPositioning?.dependence, RADIO_TRANSFER);

  //
  // Waschen und Kleiden
  //
  // Grundpflege
  resultDictionary["108"] = findElementLabel(questionnaire?.questionnaireData?.hygiene?.basic, RADIO_DEPENDENCY_LEVELS_EXTENDED);
  // Körperpflege erfolgt komplett im Bett
  resultDictionary["109"] = findElementLabel(questionnaire?.questionnaireData?.hygiene?.onlyInBed, RADIO_NO_YES);
  // Gewohnheiten / Probleme bei der Körperpflege
  resultDictionary["110"] = getProperOutput(questionnaire?.questionnaireData?.hygiene?.details);

  //
  // Essen und Trinken
  //
  // Nahrung
  resultDictionary["111"] = getProperOutput(questionnaire?.questionnaireData?.food?.diet?.value ? questionnaire?.questionnaireData?.food?.diet?.value?.map((elem) => findElementLabel(elem, CHECK_FOOD_DIET)).join(" | ") : "-");
  // Welche spezielle Diät/Diabetes?
  resultDictionary["112"] = getProperOutput(questionnaire?.questionnaireData?.food?.diet?.special);
  // Welche Nahrungsallergien?
  resultDictionary["113"] = getProperOutput(questionnaire.questionnaireData.food?.allergies?.details);
  // Welche Hilfe bei der Nahrungszubereitung?
  resultDictionary["114"] = getProperOutput(questionnaire?.questionnaireData?.food?.preparationHelp?.details);
  // Welche Hilfe bei der Nahrungsaufnahme?
  resultDictionary["115"] = getProperOutput(questionnaire?.questionnaireData?.food?.intakeHelp?.details);


  // Erährungszustand
  resultDictionary["116"] = getProperOutput(questionnaire?.questionnaireData?.food?.nutritionalStatus);
  // Trinkmenge
  resultDictionary["117"] = findElementLabel(questionnaire?.questionnaireData?.food?.fluidIntake?.value, RADIO_FOOD_FLUID_INTAKE);
  // Max. Trinkmenge(in ml/Tag)
  resultDictionary["118"] = getProperOutput(questionnaire?.questionnaireData?.food?.fluidIntake?.mlPerDay);
  // Anmerkungen
  resultDictionary["119"] = getProperOutput(questionnaire?.questionnaireData?.food?.notes);

  //
  // Ausscheiden
  //
  // Toilettengang
  resultDictionary["120"] = findElementLabel(questionnaire?.questionnaireData?.excretion?.toilet, RADIO_EXCRETION);
  // Weitere Angaben
  resultDictionary["121"] = getProperOutput(questionnaire?.questionnaireData?.excretion?.other ? questionnaire?.questionnaireData?.excretion?.other?.map((elem) => findElementLabel(elem, CHECK_EXCRETION_OTHER)).join(" | ") : "-");
  // Urininkontinenz
  resultDictionary["122"] = findElementLabel(questionnaire?.questionnaireData?.excretion?.incontinenceUrinary, RADIO_NO_SOMETIMES_ALWAYS);
  // Stuhlinkontinenz
  resultDictionary["123"] = findElementLabel(questionnaire?.questionnaireData?.excretion?.incontinenceFecal, RADIO_NO_SOMETIMES_ALWAYS);
  // Inkontinenzversorgung
  resultDictionary["124"] = findElementLabel(questionnaire?.questionnaireData?.excretion?.incontinenceCare?.dependence, RADIO_INCONTINENCE_CARE);
  // Anmerkung
  resultDictionary["125"] = getProperOutput(questionnaire?.questionnaireData?.excretion?.details);

  //
  // Atmen
  //
  resultDictionary["126"] = getProperOutput(questionnaire?.questionnaireData?.breathing?.impediments ? questionnaire?.questionnaireData?.breathing?.impediments?.map((elem) => findElementLabel(elem, CHECK_BREATHING_IMPEDIMENTS)).join(" | ") : "-");
  // Sicherheit - Es besteht ein Hausnotrufsystem
  resultDictionary["127"] = findElementLabel(questionnaire?.questionnaireData?.safety?.homeEmergencyCallServiceExists, RADIO_NO_YES);

  //
  // Bewusstsein und Orientierung
  //
  // Bewusstseinzustand / Vigilanz
  resultDictionary["128"] = findElementLabel(questionnaire?.questionnaireData?.safety?.stateOfConciousness, RADIO_SAFETY_CONSCIOUSNESS);
  // Medikamenteneinnahme
  resultDictionary["129"] = findElementLabel(questionnaire?.questionnaireData?.safety?.medicationIntake, RADIO_SAFETY_MEDICATION_INTAKE);
  // Hinlauftendenz / Weglauftendenz
  resultDictionary["130"] = findElementLabel(questionnaire?.questionnaireData?.safety?.wanderingTendency?.value, RADIO_NO_YES);

  //
  // Einschränkungen in der Orientierung
  //
  // Einschränkungen in der Orientierung
  resultDictionary["131"] = findElementLabel(questionnaire?.questionnaireData?.safety?.safety?.basic, RADIO_NONE_SOMETIMES_ALWAYS);
  // Zeitlich
  resultDictionary["132"] = findElementLabel(questionnaire?.questionnaireData?.safety?.orientationProblems?.temporal, RADIO_NONE_SOMETIMES_ALWAYS);
  // Örtlich
  resultDictionary["133"] = findElementLabel(questionnaire?.questionnaireData?.safety?.orientationProblems?.positional, RADIO_NONE_SOMETIMES_ALWAYS);
  // zur Person
  resultDictionary["134"] = findElementLabel(questionnaire?.questionnaireData?.safety?.orientationProblems?.personal, RADIO_NONE_SOMETIMES_ALWAYS);
  // situativ
  resultDictionary["135"] = findElementLabel(questionnaire?.questionnaireData?.safety?.orientationProblems?.situational, RADIO_NONE_SOMETIMES_ALWAYS);

  //
  // Sich beschäftigen
  //
  // Tagesgestaltung / Beschäftigung
  resultDictionary["136"] = findElementLabel(questionnaire?.questionnaireData?.occupation?.dependence, RADIO_DEPENDENCY_LEVELS);
  // true = aufwecken |false = ausschlafen lassen
  resultDictionary["137"] = findElementLabel(questionnaire?.questionnaireData?.occupation?.dailyRoutine?.letSleep, RADIO_YES_NO);
  // Wecken um
  resultDictionary["138"] = `${questionnaire.questionnaireData.occupation.dailyRoutine.wakeTime} Uhr`;
  //
  // Tagesstruktur
  //
  // Morgens
  resultDictionary["139"] = getProperOutput(questionnaire?.questionnaireData?.occupation?.dailyRoutine?.morning);
  // Vormittags
  resultDictionary["140"] = getProperOutput(questionnaire?.questionnaireData?.occupation?.dailyRoutine?.forenoon);
  // Mittags
  resultDictionary["141"] = getProperOutput(questionnaire?.questionnaireData?.occupation?.dailyRoutine?.noon);
  // Nachmittags
  resultDictionary["142"] = getProperOutput(questionnaire?.questionnaireData?.occupation?.dailyRoutine?.afternoon);
  // Abends
  resultDictionary["143"] = getProperOutput(questionnaire?.questionnaireData?.occupation?.dailyRoutine?.evening);
  // Ehemalige berufliche Tätigkeit
  resultDictionary["144"] = getProperOutput(questionnaire?.questionnaireData?.occupation?.formerJob);
  // Hobbys und Interessenbeschreibung
  resultDictionary["145"] = getProperOutput(questionnaire?.questionnaireData?.occupation?.hobbies);
  // Aktuelle Therapien
  resultDictionary["146"] = getProperOutput(questionnaire?.questionnaireData?.occupation?.currentTherapies?.value ? questionnaire?.questionnaireData?.occupation?.currentTherapies?.value?.map((elem) => findElementLabel(elem, CHECK_CURRENT_THERAPIES)).join(" | ") : "-");
  // Besuch von Tagespflege / Demenzcafé / Dialyse?
  resultDictionary["147"] = findElementLabel(questionnaire?.questionnaireData?.occupation?.outOfHomeAppointment?.value, RADIO_NO_YES);
  // Wie häufig
  resultDictionary["148"] = getProperOutput(questionnaire?.questionnaireData?.occupation?.outOfHomeAppointment?.frequency);
  // Weitere Therapien
  resultDictionary["149"] = getProperOutput(questionnaire?.questionnaireData?.occupation?.currentTherapies?.other);


  //
  // Kommunikation
  //
  // Sprachen
  resultDictionary["150"] = getProperOutput(questionnaire?.questionnaireData?.communication?.language?.value ? questionnaire?.questionnaireData?.communication?.language?.value?.map((elem) => findElementLabel(elem, RADIO_LANGUAGES)).join(" | ") : "-");
  // Welche Fremdsprachen
  resultDictionary["151"] = getProperOutput(questionnaire?.questionnaireData?.communication?.language?.other);
  //
  // Einschränkungen Kommunikation
  //
  resultDictionary["152"] = getProperOutput(questionnaire?.questionnaireData?.communication?.impediments?.speech ? questionnaire?.questionnaireData?.communication?.impediments?.speech?.map((elem) => findElementLabel(elem, CHECK_SPEECH_IMPEDIMENTS)).join(" | ") : "-");
  // Hören
  resultDictionary["153"] = findElementLabel(questionnaire?.questionnaireData?.communication?.impediments?.hearing, CHECK_HEARING_IMPEDIMENTS);
  // Sehen
  resultDictionary["154"] = findElementLabel(questionnaire?.questionnaireData?.communication?.impediments?.visual, CHECK_VISUAL_IMPEDIMENTS);
  // Sehvermögen zu"
  resultDictionary["155"] = `${questionnaire?.questionnaireData?.communication?.impediments?.strengthOfVision} %`;

  //
  // Sinn finden
  //
  // Mentale Verfassung
  resultDictionary["156"] = getProperOutput(questionnaire?.questionnaireData?.mentalState ? questionnaire?.questionnaireData?.mentalState?.value?.map((elem) => findElementLabel(elem, CHECK_MENTAL_STATE)).join(" | ") : "-");
  // Sonstiges zur mentalen Verfassung
  resultDictionary["157"] = getProperOutput(questionnaire?.questionnaireData?.mentalState?.other);

  //
  // Schmerzen und Schmerzbewältigung
  //
  // Ruheschmerz
  resultDictionary["158"] = findElementLabel(questionnaire?.questionnaireData?.pain?.level?.resting, RADIO_ZERO_TO_TEN);
  // Belastungsschmerz
  resultDictionary["159"] = findElementLabel(questionnaire?.questionnaireData?.pain?.level?.strained, RADIO_ZERO_TO_TEN);
  // Grund
  resultDictionary["160"] = getProperOutput(questionnaire?.questionnaireData?.pain?.reason);
  // Behandlung
  resultDictionary["161"] = getProperOutput(questionnaire?.questionnaireData?.pain?.therapyVia);
  // Anmerkungen
  resultDictionary["162"] = getProperOutput(questionnaire?.questionnaireData?.pain?.notes);

  //
  // Hilfsmittel
  //
  // Hilfsmittel in Verwendung
  resultDictionary["163"] = getProperOutput(questionnaire?.questionnaireData?.aid?.aidsInHousehold ? questionnaire?.questionnaireData?.aid?.aidsInHousehold?.map((elem) => findElementLabel(elem, CHECK_AIDS_IN_HOUSEHOLD)).join(" | ") : "-");
  // Hilfsmittel - Anmerkungen / Weitere
  resultDictionary["164"] = getProperOutput(questionnaire?.questionnaireData?.aid?.notes);

  //
  // Weitere an der Betreuung beteiligte Personen / Einrichtungen
  //
  // Wer lebt noch im Haushalt?
  resultDictionary["165"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.additionalPeople);
  // Wer ist sonst an der Betreuung beteiligt?
  resultDictionary["166"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.tasksForContacts);

  //
  // Gewünschte hauswirtschaftliche Dienstleistungen
  //
  // Hauswirtschaft
  resultDictionary["167"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.tasks?.other ? questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.tasks?.other?.map((elem) => findElementLabel(elem, CHECK_HOUSEHOLD_TASKS)).join(" | ") : "-");
  // Zahl zu versorgende Personen
  resultDictionary["168"] = findElementLabel(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.noOfPeople, RADIO_NO_OF_PEOPLE);
  // Weitere Wünsche
  resultDictionary["169"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.tasks?.wishes ? questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.tasks?.wishes?.map((elem) => findElementLabel(elem, CHECK_HOUSEHOLD_WISHES)).join(" | ") : "-");

  //
  // Verfügbarkeit finanzieller Mittel und Arbeitsmittel
  //
  // Sind alle im Haushalt notwendigen Arbeitsmittel (z.B. Staubsauger, Wischmop, Reinigungsmittel) vorhanden?
  resultDictionary["170"] = findElementLabel(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.cleaningSupplies, CHECK_HOUSEHOLD_CLEANING);
  // Angemessenes Haushaltsbudget für Mahlzeiten, gemeinsame Freizeit, Aktivitäten und Fortbewegen im Rahmen der Betreuung vorhanden?
  resultDictionary["171"] = findElementLabel(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.budget, CHECK_HOUSEHOLD_CLEANING);

  //
  // Wohnsituation
  //
  // Wohnlage und -art
  resultDictionary["172"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.residentialSituation ? questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.residentialSituation?.map((elem) => findElementLabel(elem, RADIO_RESIDENTIAL_SITUATION)).join(" | ") : "-");
  // Anzahl Zimmer?
  resultDictionary["173"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.noOfRooms);
  // Wohnfläche (in m²)
  resultDictionary["174"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.squareMeters);
  // Entferung Einkaufsmöglichkeiten
  resultDictionary["175"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.shoppingDistanceOnFoot);
  //
  // Ausstattung des Wohnbereichs der Betreuungskraft
  //
  // Raum-Art?
  resultDictionary["176"] = findElementLabel(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.roomType, NURSE_ACCOMODATION);
  // Raum-Reinigung?
  resultDictionary["177"] = findElementLabel(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.room, CHECK_HOUSEHOLD_CLEANING);
  // Schrank, Bett, Bettzeug, Tisch sowie Sitzgelegenheit
  resultDictionary["178"] = findElementLabel(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.furniture, CHECK_HOUSEHOLD_CLEANING);
  // Das Zimmer ist beheiz- und verschließbar sowie hygienisch einwandfrei und verfügt über ein Fenster mit Tageslichteinfall
  resultDictionary["179"] = findElementLabel(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.roomLivingStandards, CHECK_HOUSEHOLD_CLEANING);

  // Kochgelegenheit
  let kitchen = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.kitchen)
  resultDictionary["181"] = findElementLabel(kitchen, CHECK_HOUSEHOLD_CLEANING);
  // Kochgelegenheit
  resultDictionary["180"] = getNurseAccomodationFeatureDetail(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.kitchenType);
  // Sanitäre Räumlichkeiten?
  let bathroom = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.bathroom);
  resultDictionary["183"] = findElementLabel(bathroom, CHECK_HOUSEHOLD_CLEANING);
  // Sanitäre Räumlichkeiten?
  resultDictionary["182"] = getNurseAccomodationFeatureDetail(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.bathroomType);

  // Wasch- und Trockengelegenheit
  resultDictionary["184"] = findElementLabel(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.washing, CHECK_HOUSEHOLD_CLEANING);
  // WLAN-Zugang (uneingeschränkt und kostenfrei im Wohnraum)
  resultDictionary["185"] = findElementLabel(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.wifi, CHECK_HOUSEHOLD_CLEANING);
  // Transportmöglichkeiten für Besorgungsfahrten und Freizeitaktivitäten (z.B. PKW, ÖPNV, planbare Mitfahrgelegenheiten, Fahrrad)
  resultDictionary["186"] = findElementLabel(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.transport, CHECK_HOUSEHOLD_CLEANING);
  // Welche Transportmöglichkeiten gibt es für die Betreuungskraft"
  resultDictionary["187"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.transportDetails);
  // Weitere Ausstattung / sonstige Anmerkungen
  resultDictionary["188"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.householdData?.nurseAccommodation?.notes);

  //
  // Anforderungen an die Betreuungskraft
  //
  // Geschlecht
  resultDictionary["189"] = findElementLabel(questionnaire?.questionnaireData?.nurseRequestParameters?.gender, RADIO_GENDER);
  // Deutsche Sprache
  resultDictionary["190"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.languageSkill?.german ? questionnaire?.questionnaireData?.nurseRequestParameters?.languageSkill?.german?.map((elem) => findElementLabel(elem, CHECK_LANGUAGE_SKILL)).join(" | ") : "-");
  // Alter
  resultDictionary["191"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.ageRange?.ranges ? questionnaire?.questionnaireData?.nurseRequestParameters?.ageRange?.ranges?.map((elem) => findElementLabel(elem, CHECK_AGE)).join(" | ") : "-");
  // Qualifikation
  resultDictionary["192"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.nurseData?.qualifications ? questionnaire?.questionnaireData?.nurseRequestParameters?.nurseData?.qualifications?.map((elem) => findElementLabel(elem, CHECK_QUALIFICATION)).join(" |  ") : "-");
  // Wünsche an die Betreuungskraft
  resultDictionary["193"] = getProperOutput(questionnaire?.questionnaireData?.nurseRequestParameters?.nurseData?.customerNotes);

  const headers = Object.keys(resultDictionary).join(delimiter);
  const values = Object.values(resultDictionary)
    .map(
      (value) => `"${value}"` // Encapsulate values in quotes to handle commas or newlines within values
    )
    .join(delimiter);
     // Add BOM to ensure proper encoding
  const bom = '\uFEFF';
  const csvString = `${bom}${headers}\n${values}`;

  //console.log("csv => ", csvString);
  return csvString;
}