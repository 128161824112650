import React, { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import { makeStyles } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';
import Button from '../../buttons/components/Button';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },

    refreshButton: {
        position: 'absolute',
        zIndex: 1000,
        top: 3,
        right: 0,
    },
}));

const Editor = ({ name, showTagsButton, showRefreshButton, onRefresh }) => {
    const classes = useStyles();
    const receiverField = useField('receiver');
    const [field, meta, helpers] = useField(name);

    const toolbar = useMemo(() => {
        const arr = ['bold', 'italic', 'underline', 'link', 'fontsizeselect', 'styleselect'];
        if (showTagsButton) {
            arr.push('tags');
        }
        arr.push('insertSpecialSignature');

        return arr.join(' ');
    }, [showTagsButton]);

    const refreshOnclick = useCallback(() => {
        onRefresh(helpers.setValue, receiverField[1].value);
    }, [helpers, receiverField, onRefresh]);

    const init = useMemo(() => {
        return {
            plugins: 'autolink link',
            toolbar,
            fontsize_formats: '9pt 10pt 11pt 12pt 13pt 14pt',
            style_formats: [
                { title: 'Schwarz', inline: 'span', styles: { color: '#000000' } },
                { title: 'Rot', inline: 'span', styles: { color: 'red' } },
                { title: 'Blau', inline: 'span', styles: { color: 'blue' } },
                { title: 'Grün', inline: 'span', styles: { color: 'green' } },
            ],
            branding: false,
            statusbar: false,
            menubar: false,
            skin_url: '/tinymce/lightgray',
            content_style: '.editor-template-tag { background-color: #DEE8F2 }',
            browser_spellcheck: true,
            //contextmenu: 'link',
            contextmenu: false,
            height: '400px',
            setup(editor) {
                editor.addButton('insertSpecialSignature', {
                    text: 'Signatur',
                    tooltip: "Einfügen ihrer Signature an der aktuellen Cursorposition",
                    onclick() {
                        editor.insertContent('<p>%%SPECIAL_SIGNATURE_POSITION%%</p>');
                    },
                });
                editor.addButton('tags', {
                    type: 'menubutton',
                    text: 'Tags',
                    icon: false,
                    menu: [
                        {
                            text: 'Auftragsnummer',
                            onclick() {
                                editor.insertContent('{{AUFTRAGSNUMMER}}');
                            },
                        },
                        {
                            text: 'Mitarbeiter',
                            menu: [
                                {
                                    text: 'Vor- und Nachname',
                                    onclick() {
                                        editor.insertContent('{{MITARBEITER}}');
                                    },
                                },
                                {
                                    text: 'Telefonnummer',
                                    onclick() {
                                        editor.insertContent('{{MITARBEITERTELEFON}}');
                                    },
                                },
                            ],
                        },
                        {
                            text: 'Kunde',
                            menu: [
                                {
                                    text: 'Begrüßung',
                                    onclick() {
                                        editor.insertContent('{{BEGRUESSUNG}}');
                                    },
                                },
                                {
                                    text: 'Anrede',
                                    onclick() {
                                        editor.insertContent('{{ANREDE}}');
                                    },
                                },
                                {
                                    text: 'Titel',
                                    onclick() {
                                        editor.insertContent('{{TITEL}}');
                                    },
                                },
                                {
                                    text: 'Vorname',
                                    onclick() {
                                        editor.insertContent('{{VORNAME}}');
                                    },
                                },
                                {
                                    text: 'Nachname',
                                    onclick() {
                                        editor.insertContent('{{NACHNAME}}');
                                    },
                                },
                                {
                                    text: 'Docu-Sign E-Signatur (Link)',
                                    onclick() {
                                        editor.insertContent('{{DOCUSIGNLINKESIGN}}');
                                    },
                                },
                            ],
                        },
                        {
                            text: 'Zu betreuende Person',
                            menu: [
                                {
                                    text: 'Anrede',
                                    onclick() {
                                        editor.insertContent('{{PATIENTANREDE}}');
                                    },
                                },
                                {
                                    text: 'Titel',
                                    onclick() {
                                        editor.insertContent('{{PATIENTTITEL}}');
                                    },
                                },
                                {
                                    text: 'Vorname',
                                    onclick() {
                                        editor.insertContent('{{PATIENTVORNAME}}');
                                    },
                                },
                                {
                                    text: 'Nachname',
                                    onclick() {
                                        editor.insertContent('{{PATIENTNACHNAME}}');
                                    },
                                },
                                {
                                    text: 'PLZ',
                                    onclick() {
                                        editor.insertContent('{{PATIENTPLZ}}');
                                    },
                                },
                                {
                                    text: 'Wohnort',
                                    onclick() {
                                        editor.insertContent('{{PATIENTORT}}');
                                    },
                                },
                            ],
                        },
                        {
                            text: 'Betreuungskraft',
                            menu: [
                                {
                                    text: 'Anrede',
                                    onclick() {
                                        editor.insertContent('{{BKANREDE}}');
                                    },
                                },
                                {
                                    text: 'Vorname',
                                    onclick() {
                                        editor.insertContent('{{BKVORNAME}}');
                                    },
                                },
                                {
                                    text: 'Nachname',
                                    onclick() {
                                        editor.insertContent('{{BKNACHNAME}}');
                                    },
                                },
                                {
                                    text: 'Agentur',
                                    onclick() {
                                        editor.insertContent('{{BKAGENTUR}}');
                                    },
                                },
                                {
                                    text: 'Telefonnummer',
                                    onclick() {
                                        editor.insertContent('{{BKPHONENUMBER}}');
                                    },
                                },
                            ],
                        },
                        {
                            text: 'BK-Einsatz',
                            menu: [
                                {
                                    text: 'BK-Anfrage-Bemerkung',
                                    onclick() {
                                        editor.insertContent('{{BKANFRAGEBEMERKUNG}}');
                                    },
                                },
                                {
                                    text: 'Beginn Betreuung',
                                    onclick() {
                                        editor.insertContent('{{BEGINN}}');
                                    },
                                },
                                {
                                    text: 'Anreise BK',
                                    onclick() {
                                        editor.insertContent('{{ANREISEDATUM}}');
                                    },
                                },
                                {
                                    text: 'Abreise BK',
                                    onclick() {
                                        editor.insertContent('{{ABREISE}}');
                                    },
                                },
                                {
                                    text: 'DLV Vertragsbeginn',
                                    onclick() {
                                        editor.insertContent('{{DLVVERTRAGSBEGINN}}');
                                    },
                                },
                                {
                                    text: 'Endpreis',
                                    onclick() {
                                        editor.insertContent('{{ENDPREIS}}');
                                    },
                                },
                            ],
                        },
                        {
                            text: 'Agentur',
                            menu: [
                                {
                                    text: 'Name',
                                    onclick() {
                                        editor.insertContent('{{AGENTURNAME}}');
                                    },
                                },
                                {
                                    text: 'Ansprechpartner Anrede',
                                    onclick() {
                                        editor.insertContent('{{AGENTURANSPRECHPARTNERANREDE}}');
                                    },
                                },
                                {
                                    text: 'Ansprechpartner Vorname',
                                    onclick() {
                                        editor.insertContent('{{AGENTURANSPRECHPARTNERVORNAME}}');
                                    },
                                },
                            ],
                        },
                        {
                            text: 'Verträge',
                            menu: [
                                {
                                    text: 'Agentur Vertragsbeginn',
                                    onclick() {
                                        editor.insertContent('{{DLVVERTRAGSBEGINN}}');
                                    },
                                },
                                {
                                    text: 'Agentur Vertragsende',
                                    onclick() {
                                        editor.insertContent('{{DLVVERTRAGSENDE}}');
                                    },
                                },
                                {
                                    text: 'Agentur gekündigt am',
                                    onclick() {
                                        editor.insertContent('{{DLVGEKUENDIGTAM}}');
                                    },
                                },
                                {
                                    text: 'Agentur Vertragspartner',
                                    onclick() {
                                        editor.insertContent('{{DLVVERTRAGSPARTNER}}');
                                    },
                                },
                                {
                                    text: 'PZH Vertragsbeginn',
                                    onclick() {
                                        editor.insertContent('{{PZHVERTRAGSBEGINN}}');
                                    },
                                },
                                {
                                    text: 'PZH Vertragsende',
                                    onclick() {
                                        editor.insertContent('{{PZHVERTRAGSENDE}}');
                                    },
                                },
                                {
                                    text: 'PZH gekündigt am',
                                    onclick() {
                                        editor.insertContent('{{PZHGEKUENDIGTAM}}');
                                    },
                                },
                            ],
                        },
                        {
                            text: 'Erhebungsbogen',
                            menu: [
                                {
                                    text: 'Link',
                                    onclick() {
                                        editor.insertContent('{{EBLINK}}');
                                    },
                                },
                                {
                                    text: 'Link-Anonym',
                                    onclick() {
                                        editor.insertContent('{{EBLINKANONYM}}');
                                    },
                                },
                                {
                                    text: 'N-Link-Anonym',
                                    onclick() {
                                        editor.insertContent('{{NEBLINKANONYM}}');
                                    },
                                },
                                {
                                    text: 'Link-Anonym Kunde',
                                    onclick() {
                                        editor.insertContent('{{EBLINKANONYMKD}}');
                                    },
                                },
                            ],
                        },
                    ],
                });
            },
        };
    }, [toolbar]);

    return (
        <div className={classes.container}>
            {showRefreshButton && (
                <Button className={classes.refreshButton} onClick={() => refreshOnclick()}>
                    Vorlage aktualisieren
                </Button>
            )}
            <TinyEditor
                value={meta.value}
                init={init}
                onEditorChange={(e) => {
                    field.onChange({ target: { name, value: e } });
                }}
            />
            <FormHelperText error>{(meta.touched && meta.error) || null}</FormHelperText>
        </div>
    );
};
export default Editor;
