import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SubjectPropType } from '../../../abilities/proptypes';
import SendMailButton from './SendMailButton';
import { NURSE_REQUEST_CANCELATION_ENTITY } from '../../../mailTemplates/entities';
import { PrefillMailPropType } from '../../../mails/proptypes';
import { updateNurseRequest } from '../../../nurseRequests/nurseRequestsSlice';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { NurseRequestDataPropType } from '../../../../proptypes/careneeds';
import { destroyCareNeed } from 'Modules/careNeeds/careNeedsSlice';
import {
    useNurseDeploymentsByCareNeedId
} from '../../../nurseDeployments/nurseDeploymentsSlice';

const SendNurseRequestCancelationButton = ({
    name,
    disabled,
    nurseRequestData,
    onSent,
    subject,
    action,
    className,
    variant,
    size,
    label,
    fullWidth,
    prefill,
    color,
    ellipsis,
}) => {
    const customerId = nurseRequestData?.customer.id;
    const careNeedId = nurseRequestData?.id;
    const agencyIds = nurseRequestData?.nurseRequests.map((nurseRequest) => nurseRequest.agency);
    const nurseRequests = nurseRequestData?.nurseRequests;

    const dispatch = useDispatch();

    const prepareExtraEntities = useCallback(() => {
        return { agencyIds, careNeedId, customerId };
    }, [agencyIds, careNeedId, customerId]);

    const [nurseDeployments,,] = useNurseDeploymentsByCareNeedId(careNeedId);
    //console.log("SendNurseRequestCancelationButton customerId=>", customerId, "nurseDeployments =>", nurseDeployments);

    const extraFields = null; //useMemo(() => null);

    const destroyEmptyCareNeed = useCallback((careNeedId, nurse_deployments) => {
        // make sure there are no nurse_deployments for this care_need
        const nurseDeploymentIds = nurse_deployments.filter((deployment) => deployment.careNeedId === careNeedId);
        //console.log("destroyEmptyCareNeed=>",nurseDeploymentIds,"nds=>",nurse_deployments);
        if (nurseDeploymentIds.length === 0) {
            //console.info("delete empty care_need =>", careNeedId);
            dispatch(destroyCareNeed({ id: careNeedId }));
        }
        else {
            //console.info("can't delete a care_need with history =>", careNeedId);
        }
    },
    [dispatch]);

    const handleSent = useCallback(
        async ({ mail, extraEntities }) => {
            // console.log('handleSent extraEntities=>', extraEntities);
            // remove all open nurse requests from this customer
            //console.log("careNeedId => ",careNeedId);
            const results = await Promise.all(
                nurseRequests.map((nurseRequest) =>
                    dispatch(
                        updateNurseRequest({
                            id: nurseRequest.id,
                            closedAt: nurseRequest.closedAt || new Date(),
                        })
                    )
                )
            );

            // now close care need, related to the nurse requests -> only when EMPTY => with no history
            destroyEmptyCareNeed(careNeedId,nurseDeployments)

            if (onSent) {
                await onSent(results);
            }
            return true;
        },
        [dispatch, onSent, nurseRequests, careNeedId, nurseDeployments,destroyEmptyCareNeed]
    );

    return (
        <SendMailButton
            name={name}
            color={color}
            variant={variant}
            startIcon={<DeleteForeverIcon />}
            disabled={disabled}
            action={action}
            subject={subject}
            entity={NURSE_REQUEST_CANCELATION_ENTITY}
            entityId={customerId}
            onSent={handleSent}
            extraEntities={prepareExtraEntities}
            extraFields={extraFields}
            className={className}
            size={size}
            label={label || 'Alle BK-Anfragen absagen'}
            fullWidth={fullWidth}
            prefill={prefill}
            ellipsis={ellipsis}
        />
    );
};

SendNurseRequestCancelationButton.propTypes = {
    name: PropTypes.string,
    onSent: PropTypes.func,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.node,
    fullWidth: PropTypes.bool,
    prefill: PrefillMailPropType,
    ellipsis: PropTypes.bool,
    confirmLabel: PropTypes.string,
    onClick: PropTypes.func,
    nurseRequestData: NurseRequestDataPropType.isRequired,
};

SendNurseRequestCancelationButton.defaultProps = {
    name: null,
    onSent: null,
    disabled: false,
    subject: null,
    action: null,
    variant: undefined,
    color: undefined,
    size: undefined,
    className: null,
    label: null,
    fullWidth: false,
    prefill: null,
    ellipsis: false,
    confirmLabel: null,
    nurseRequestData: null,
};

export default SendNurseRequestCancelationButton;
