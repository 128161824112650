import moment from 'moment';
import {
    NURSE_DEPLOYMENT_TYPE_NURSE,
    NURSE_DEPLOYMENT_TYPE_PAUSE,
    NURSE_DEPLOYMENT_TYPE_PAUSE_FEE_BASED,
} from './types';

export const checkLegalGapDays = (nursedeployments, currenNurseDeployment, newbillingFromDate, newDeploymentType) => {

    const prev = getPreviousDeployment(nursedeployments, currenNurseDeployment, newbillingFromDate, newDeploymentType);
    if (prev === null || prev === undefined)
    {
        console.error("no prev found!!!");
        return { isValid: true };
    }
    const startDate = (prev?.type === NURSE_DEPLOYMENT_TYPE_PAUSE || prev?.type === NURSE_DEPLOYMENT_TYPE_PAUSE_FEE_BASED) ? prev?.careTill : prev?.billingTill;

    const gapDays = countGapDays(startDate, newbillingFromDate);
    //console.log("gaps => ",gapDays, " start=>",startDate,"end=>",newbillingFromDate,prev.type,"=>",newDeploymentType);
    //console.log("prev =>",prev?.type,"new =>",currenNurseDeployment?.type,"gaps => ",gapDays, " start=>",startDate,"end=>",newbillingFromDate);

    // Wenn BK gefolgt von BK: Darf dasselbe Datum sein
    if (prev.type === NURSE_DEPLOYMENT_TYPE_NURSE && newDeploymentType === NURSE_DEPLOYMENT_TYPE_NURSE )
        if (gapDays <= 0 || gapDays === 1) {
            return { isValid: true };
        } else {
            if (gapDays > 0)
                return { isValid: false, message: 'Es darf keinen Lückentag zwischen den Einsätzen geben' };
            else
                return { isValid: false, message: 'BK überschneidet sich mit einer anderen BK' };
        }

    // Wenn BK gefolgt von Pause: Muss Datum von = Datum bis + 1 sein
    // Wenn Pause gefolgt von BK: Muss Datum von = Datum bis + 1 sein
    // Wenn Pause gefolgt von Kündigungsfrist: Muss Datum von = Datum bis + 1 sein
    // Wenn BK gefolgt von Kündigungsfrist: Muss Datum von = Datum bis + 1 sein
    if (gapDays === 1) {
        return { isValid: true };
    } else {
        if (gapDays > 0)
            return { isValid: false, message: 'Es darf keinen Lückentag zwischen den Einsätzen/Pausen etc. geben' };
        else
            return { isValid: false, message: 'Überschneidet sich mit einem anderem Einsatz/Pause etc.' };
    }
}

const countGapDays = (startDate, endDate) => {
    if (!startDate || !endDate) 
        return 0;
    const start = moment(startDate);
    const end = moment(endDate);

    return end.diff(start, 'days');
};

const getPreviousDeployment = (deployments, currenNurseDeployment, dateStart, newType) => {
    if (!Array.isArray(deployments) || deployments.length === 0)
        return null;

    const parsedDateStart = new Date(dateStart);
    const excludedId = currenNurseDeployment?.id;

    return deployments
        .filter(dep => {
            if (dep.id === excludedId)
                return false;

            const depDate = (dep.type === NURSE_DEPLOYMENT_TYPE_PAUSE || dep.type === NURSE_DEPLOYMENT_TYPE_PAUSE_FEE_BASED)
                ? new Date(dep.careFrom)
                : new Date(dep.billingFrom);

            return depDate <= parsedDateStart;
        })
        .sort((a, b) => new Date(b.billingFrom || b.careFrom) - new Date(a.billingFrom || a.careFrom)) // Sort descending
        [0] || null; // Return the most recent past deployment
};
