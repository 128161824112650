import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { homePath } from '../../nav/paths';

const CountdownTimer = ({ initialTime, info }) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const history = useHistory();

    useEffect(() => {
        if (timeLeft <= 0) {
            history.push(homePath); // Navigate to home when timer runs out
            return;
        }

        const timerId = setInterval(() => {
            setTimeLeft(timeLeft - 1000);
        }, 1000);

        return () => clearInterval(timerId);
    }, [timeLeft, history]);

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
        const seconds = String(totalSeconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    return (
        <div>
            {info} {formatTime(timeLeft)}
        </div>
    );
};

export default CountdownTimer;