import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
    username: Yup.string().required('Pflichtfeld'),
    password: Yup.string().required('Pflichtfeld'),
});

export const setPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Pflichtfeld'),
    passwordConfirmation: Yup.string().required('Pflichtfeld'),
});

export const otpSchema = Yup.object().shape({
    otp: Yup.string().required('OTP ist erforderlich'),
});